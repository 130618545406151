import { createAsyncThunk } from "@reduxjs/toolkit";
import apiEndpoints from "../../const/apiEndpoints";
import history from "../../const/history";
import ROUTES from "../../const/routes";
import api from "../../services";
import {
  GetLevel,
  GetTest,
  optResponse,
  QuestionType,
  SelectedStreamType,
  SelectedTest,
  StreamList,
} from "./types";

export const handleStreamMaster = createAsyncThunk(
  "streamMaster/getstreamMaster",
  async (): Promise<Array<StreamList>> => {
    const response = await api.get(apiEndpoints.getStream);
    return response.data.message;
  }
);

export const SelectedStream = createAsyncThunk(
  "test/GetTestByStream",
  async (requestPayload: SelectedStreamType): Promise<GetTest> => {
    const response = await api.post(
      `${apiEndpoints.GetTestByStream}`,
      requestPayload
    );
    return response.data.message;
  }
);

export const GetQuestionByTest = createAsyncThunk(
  "test/GetTestByTest",
  async (requestPayload: SelectedTest): Promise<QuestionType> => {
    const response = await api.post(
      apiEndpoints.GetQuestionByTest,
      requestPayload
    );
    if (response.data) {
      history.push(ROUTES.TEST)
    }
    return response.data.message;
  }
);

export const getLevel = createAsyncThunk(
  "level/GetLevel",
  async (): Promise<Array<GetLevel>> => {
    const response = await api.get(apiEndpoints.getLevel);
    return response.data.message;
  }
);

export const getOTP = createAsyncThunk(
  'opt/g',
  async (requestPayload: any,): Promise<optResponse> => {
    const response = await api.post(`${apiEndpoints.otp}`, requestPayload)
    return response?.data
  }
)

export const validateOTP = createAsyncThunk(
  'otp/ValidateOTP',
  async (requesPayload: any): Promise<any> => {
    const response = await api.post(`${apiEndpoints.validateOTP}`, requesPayload)
    return response?.data
  }
)

export const updateStream = createAsyncThunk(
  'updateStream',
  async (requestPayload: any): Promise<any> => {
    const response = await api.post(`${apiEndpoints.updateStream}`, requestPayload)
    return response?.data
  }
)

export const validateEmail = createAsyncThunk(
  'mailValidate',
  async (requestPayload: any): Promise<any> => {
    const response = await api.post(`${apiEndpoints.emailValiadte}`, requestPayload)
    return response?.data
  }
)