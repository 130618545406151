import { ReactElement, forwardRef } from "react";
import Multiselect from "multiselect-react-dropdown";
import { DropdownProps } from "./typings";
import { DropdownWrapper, Title, ErrorWrapper } from "./subcomponents";

const ReactDropdown = forwardRef(({
  width,
  isDisabled,
  title,
  isRequired,
  singleSelect,
  handleSelect,
  error,
  dropdownList,
  placeholder,
  selectedValues
}: DropdownProps, ref: any): ReactElement => {

  let searchBox;
  if (error) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    searchBox = {
      border: "1px solid #e90a32"
    }
  }

  return (
    <DropdownWrapper
      width={width}
      isDisabled={isDisabled}
      id="editable-dropdown"
    >
      {title && (
        <Title>
          {`${title}`}
          {isRequired && <strong>*</strong>}
        </Title>
      )}
      <Multiselect
        id="input"
        singleSelect={singleSelect || false}
        displayValue="name"
        showArrow={true}
        ref={ref}
        selectedValues={selectedValues}
        placeholder={placeholder}
        onRemove={function noRefCheck() { }}
        onSelect={
          handleSelect
        }
        options={dropdownList}
        style={{
          chips: {
            background: "#ffffff",
            color: "#3335CF",
            fontWeight: '600',
            height:'11px',
            fontSize: '12px',
            border: 'none',
            fontFamily: 'Montserrat'
          },
          multiselectContainer: {
            color: "#3335CF",
            background: "#fff", 
            border:'none' 
          },
          option: {
            color: "#3335CF",
            background: "#fff",
            fontFamily: 'Montserrat',
            fontSize: '13px',
            overflow: 'auto',
            fontWeight: '600',
          },
          ...{
            searchBox: {
              border: "none", 
            }
          },
          inputField: {
            fontFamily: 'Montserrat',
            fontSize: '13px',
            padding: '2px 0 2px 6px',
          }, 
        }}
      />
      <ErrorWrapper >{error}</ErrorWrapper>
    </DropdownWrapper>
  );
});

export default ReactDropdown;
