import styled, { css } from "styled-components";

type Custom = {
  height?: number
}

const PageWrapper = styled.div<Custom>`
  margin: 24px 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  overflow-x: hidden;
  ${({ height }) => height && css`
    min-height: ${height}px;`
  }
`;

export default PageWrapper;
