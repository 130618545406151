const apiEndpoints = {
  loginend: "User_Login",
  getStream: "Get_Stream",
  GetQuestionByTest: "Get_Question",
  GetTestByStream: "Get_Test",
  logout: "Logout",
  PostAnswers: "StoreResult",
  addUser: "Add_User",
  getProfile: "Get_Profile",
  getLevel: "Get_Level",
  getCountries: "Get_Countries",
  getState: "Get_States",
  getCountryPin: "Contact_Code",
  otp: 'otp',
  validateOTP: 'otp_validation',
  updateStream: 'Update_User_Stream',
  getResultByuserId: 'GetStoreResult',
  emailValiadte: 'Email_validation'
};

export default apiEndpoints;
