import { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import { QuestionSectionProps } from "./typings";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { useHistory } from "react-router-dom";
import { FlexWrapper, Input, ToastMessage } from "../../components";
import { Button, Col, Row } from "react-bootstrap";
import ROUTES from "../../const/routes";
import { postAnswer } from "../../redux/TestApi/api";
import { getTestId } from "../../helpers/dropdown";
import { SelectedAnswers } from "../../redux/TestApi/types";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Background from "../../assests/testHeader.svg";
import { alphabet } from "./const";

import { colors } from "../../const/theme";
import { updateStream } from "../../redux/streamMaster/api";
import MainHeader from "../../components/Header";
import PageBanner from "../../components/PageBanner";

export const FlexWrap = styled.div`
  display: flex;
  background: url(${Background});
  background-position: center;
  background-size: 1000px;
  justify-content: space-around;
  width: 100%;
  background-color: #fff2e1;
  height: 110px;
  margin-top: 86px;
  align-items: center;
  padding: 8px;

  #logoWrapper {
    cursor: context-menu;
    display: flex;
    &:hover {
      background: #3335cf;
    }
    @media (max-width: 1000px) {
    }
    @media (max-width: 650px) {
      width: auto;
      display: flex;
      margin: auto;
    }
    @media (max-width: 450px) {
      width: auto;
      display: flex;
      margin: auto;
      
    }
  }
  @media (max-width: 1000px) {
    width: 100%;
  }
  @media (max-width: 650px) {
    width: 100%;
  }
  @media (max-width: 450px) {
    width: 100%;
    
  }
`;

export const PageWrapper = styled.div`
  width: 100%;
`;

interface bold {
  fontSize?: string;
  fontWeight?: string;
  padding?: boolean;
  color?: string;
  paddingRight?: boolean;
}
const Bold = styled.p<bold>`
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "700")};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "21px")};
  font-family: "Montserrat", sans-serif;
  margin: auto 0;
  padding: ${({ padding }) => (padding ? "14px" : "0")};
  padding-right: ${({ paddingRight }) => (paddingRight ? "190px" : "0")};

  
`;
const QuestionContainer = styled.div`
  width: 100%;
  background-color: #fcfcff;
  

  @media (min-width: 450px) {
    width: 100%;
  }

  @media (min-width: 700px) {
    padding-left: 9%;
    border-right: 4px solid #d4d4d4;
    width: 75%;
  }
`;
const QuestionNo = styled.span`
  font-weight: 700;
  color: #0f1043;
  font-size: 16px;
  margin-left: 8px;
  font-family: "Montserrat", sans-serif;
  opacity: 0.6;
`;

const TestWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  font-family: "Montserrat", sans-serif;
  padding: 0 0 0 15px;
  align-items: baseline;
  gap: 10;
`;

interface OptionsProps {
  $isSelected?: boolean;
}

const Optoins = styled.div<OptionsProps>`
  display: flex;
  align-items: center;
  width: 100%;
  background: ${({ $isSelected, theme }) =>
    $isSelected ? theme?.sideMenu.menu.background : colors.white};
  .activeButton {
    background: red;
  }
  background-color: #fcfcff;
  padding: 2%;
  gap: 10;

  @media screen and (min-width: 650px) {
  }
`;

const OptionButton = styled(Button)`
  background: linear-gradient(90deg, #0000001a 40px, #fcfcff 20px);
  &.btn-primary {
    background: linear-gradient(90deg, #0000001a 40px, #fcfcff 20px);
  }
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: none;
  color: #000124;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  height: 40px;
  box-shadow: 0px 1px 4px lightgray;
  opacity: 1;
  cursor: pointer;
  &.active,
  &:hover,
  &:focus {
    color: black;
    background: linear-gradient(90deg, #3335cf 40px, #c5c5ff 20px);
    &.btn-primary {
      background: linear-gradient(90deg, #3335cf 40px, #c5c5ff 20px);
    }
  }
  .activeButton {
    background: red;
  }

  display: grid;
    grid-template-columns: 40px 1fr;
    align-items: center;
    padding: 10px 0;

  @media screen and (min-width: 650px) {
  }
`;

const OptionButtonProjectHead = styled(Button)`
  background: linear-gradient(90deg, #0000001a 40px, #fcfcff 20px);
  &.btn-primary {
    background: linear-gradient(90deg, #0000001a 40px, #fcfcff 20px);
  }
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: none;
  color: #000124;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  min-height: 40px;
  box-shadow: 0px 1px 4px lightgray;
  opacity: 1;
  cursor: pointer;
  &.active,
  &:hover,
  &:focus {
    color: black;
    background: linear-gradient(90deg, #3335cf 40px, #c5c5ff 20px);
    &.btn-primary {
      background: linear-gradient(90deg, #3335cf 40px, #c5c5ff 20px);
    }
  }
  .activeButton {
    background: red;
  }
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
  padding: 10px 0;
}
`;

const SubmitButton = styled(Button)`
  height: 35px;
  width: auto;
  background: #ff7b00;
  border: none;
  color: #ffffff;
  opacity: 1;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  &:hover,
  &:active,
  &:focus {
    background: #ff7b00 0% 0% no-repeat padding-box;
  }
  &:disabled {
    background-color: #ff7b00;
    border-color: #ff7b00;
  }
`;

export const Logos = styled.img`
  width: 12%;
  float: left;
  @media (max-width: 600px) {
    width: 10%;
  }
`;

export const OptionText = styled.span`
  text-align: left;
  padding: 0 10px;
  color: #000124;
  font-weight: 450;
  font-family: "Montserrat", sans-serif;
`;

export const OptionTextProjectHead = styled.span`
  text-align: left;
  padding: 0 10px;
  color: #000124;
  font-weight: 450;
  font-family: "Montserrat", sans-serif;
`;

export const Option = styled.span<OptionsProps>`
  float: left;
  display: flex;
  color: #000124;
  justify-content: center;
  width: 100%;

  font-weight: 450;
  ${OptionButton}:hover & {
    color: white;
  }
  ${OptionButton}:focus & {
    color: white;
  }

  @media (max-width: 800px) {
    font-size: 10px;
  }
`;

/*-----------------Circular bar----------------- */

export const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 74%;
`;

export const ProgressDivider = styled.div`
  width: 100%;
  background-color: #f6f6fe;

  @media (min-width: 700px) {
    width: 25%;
  }
`;

export const ProgessWrapper = styled.div`
  width: 100%;
  max-width: 80%;
  margin: 20px auto;
  text-align: center;

  @media (min-width: 650px) {
    margin: 30% auto;
  }

  @media (min-width: 749px) {
    width: 53%;
  }
`;

export const Progess = styled(CircularProgressbar)`
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  width: 60%;

  @media (min-width: 650px) {
    width: 100%;
  }

`;

export const LevelDetaisl = styled(Button)`
  margin: ${({ margin }) => (margin ? `${margin}%` : "auto")};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : "10px")};
  margin-left: ${({ marginLeft }) => (marginLeft ? `${marginLeft}px` : "auto")};
  margin-right: ${({ marginRight }) =>
    marginRight ? `${marginRight}px` : "auto"};
  background-color: #3335cf;
  border: none;
  min-width: 203px;
  height: auto;
  cursor: context-menu;
  font-family: "Montserrat", sans-serif;
  &:hover,
  &:focus {
    background-color: #3335cf;
  }
  &:active {
    background-color: #3335cf;
  }
  #logoWrapper {
    font-family: "Montserrat", sans-serif;
  }
  @media (max-width: 1000px) {
  }
  @media (max-width: 650px) {
  }
  @media (max-width: 450px) {
    font-size: 10px;
    min-width: 103px;    

  }
`;

export const WholeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 86px;
`;

export const QuestionSection = ({
  data,
  activeQuestions,
  quesId,
  isMultiOption,
  options,
  onSetActiveQuestion,
}: QuestionSectionProps): ReactElement => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    TestId,
    level,
    SelectAnswer,
    TestPayload,
    userId,
    userNameuser,
    uselectedStream,
  } = useSelector(
    (state: RootState) => ({
      TestId: state.stream.questions,
      level: state.stream.level.test_level,
      SelectAnswer: state.Test.AnswerList as Array<SelectedAnswers>,
      TestPayload: state.Test.TestPayload,
      userId: state.login.userInfo?.userId,
      login: state.login,
      userNameuser: state.login.userInfo?.user_name,
      uselectedStream: state.stream.uselectedStream,
    }),
    shallowEqual
  );

  //TestId AND TestName
  const TestID = TestId ? getTestId(TestId) : [];
  const [TestName] = TestID.map((x) => {
    return x.name;
  });
  const [Testid] = TestID.map((x) => {
    return x.id;
  });

  //changeEvent
  const [selectedAnswer, setSelectedAnswer] = useState(SelectAnswer);
  const [FinalPayload, setFinalPayload] = useState(TestPayload);
  const QuestionProgress = Math.floor(
    ((activeQuestions + 1) / data.length) * 100
  );
  const [percent, setPercent] = useState(0);
  const [otherValues, setotherValues] = useState("");
  const [Answer, setAnswer] = useState("");
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState({ isTrue: false, isFalse: false });
  const [isMultiplActive, setIsMultipleActive] = useState(false);

  const [answerValues, setanswerValues] = useState(false);

  const answerHandler = (value: any) => {
    setAnswer(value);
    setIsMultipleActive(!isMultiplActive);
  };

  const OtherAnswerHandler = (value: string) => {
    setotherValues(value);
  };

  const changeHandler = () => {
    if (Answer?.trim() === "Others") {
      setShow(true);
    } else {
      setShow(false);
    }
    const isQuesIncluded = selectedAnswer.some(
      (item) => item?.questionId === `${quesId[activeQuestions]}`
    );

    if (isQuesIncluded) {
      const updatedSelection = selectedAnswer.map((item) => {
        if (item?.questionId === `${quesId[activeQuestions]}`) {
          if (Answer?.trim() === "Others") {
            return {
              questionId: `${quesId[activeQuestions]}`,
              ans: otherValues,
            };
          } else if (Answer !== "") {
            return {
              questionId: `${quesId[activeQuestions]}`,
              ans: Answer,
            };
          }
        }
        return item;
      });
      setSelectedAnswer(updatedSelection);
    } else {
      if (Answer?.trim() === "Others") {
        setSelectedAnswer([
          ...selectedAnswer,
          {
            questionId: `${quesId[activeQuestions]}`,
            ans: otherValues,
          },
        ]);
      } else if (Answer !== "") {
        setSelectedAnswer([
          ...selectedAnswer,
          {
            questionId: `${quesId[activeQuestions]}`,
            ans: Answer,
          },
        ]);
      }
    }
  };

  //NextButton
  const [buttonValue, SetbuttonValue] = useState("Next");

  const nextClickHandle = () => {
    setShow(false);
    setotherValues("");
    if (Answer === "") {
      setanswerValues(true);
    } else if (Answer !== "") {
      setanswerValues(false);
    } else if (otherValues === "") {
      setanswerValues(true);
    } else if (otherValues !== "") {
      setanswerValues(false);
    }
    if (data.length > activeQuestions + 1) {
      if (
        selectedAnswer[activeQuestions]?.ans === undefined ||
        selectedAnswer[activeQuestions] === undefined
      ) {
      } else {
        onSetActiveQuestion(activeQuestions + 1);
        setPercent(QuestionProgress);
        setIsActive({ ...isActive, isTrue: false, isFalse: false });
        setSelectedOption(-1);
      }
    } else {
      onSetActiveQuestion(data.length - 1);
    }
    //Button Value
    if (quesId[activeQuestions] === quesId[data.length - 2]) {
      SetbuttonValue("Submit");
    }

    if (quesId[activeQuestions] === quesId[data.length - 1]) {
      if (
        selectedAnswer[activeQuestions]?.ans === undefined ||
        selectedAnswer[activeQuestions] === undefined
      ) {
      } else {
        dispatch(
          postAnswer({ ...FinalPayload, id: uselectedStream?.streamID })
        );
        dispatch(
          updateStream({
            stream: uselectedStream?.streamID,
            level: uselectedStream?.streamID,
            id: userId,
          })
        );
        history.push(ROUTES.RESULTCOPY);
      }
    }
    setAnswer("");
  };

  useEffect(() => {
    setFinalPayload({
      testID: Testid,
      userID: userId,
      testLevel: level,
      answer: selectedAnswer,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAnswer]);

  useEffect(() => {
    changeHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Answer]);

  useEffect(() => {
    changeHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherValues]);

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, []);

  useEffect(() => {
    if (isActive?.isTrue === true) {
      setIsActive({ ...isActive, isFalse: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive?.isTrue]);

  useEffect(() => {
    if (isActive?.isFalse === true) {
      setIsActive({ ...isActive, isTrue: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive?.isFalse]);

  const [selectedOption, setSelectedOption] = useState(-1);

  return (
    <PageWrapper>
      <MainHeader />
      <WholeWrapper>
        <PageBanner
          heading="Your brightest path is out there. Get started!!"
          testName={TestName}
          id={parseInt(uselectedStream?.streamID)}
          level={level}
        />
        <ContentWrapper>
          <ToastMessage
            show={answerValues}
            onCloseHandler={() => {
              setanswerValues(false);
            }}
            message="Please Select Answer!"
            top={"110px"}
            bg="danger"
          ></ToastMessage>
          <QuestionContainer>
            <Bold
              fontSize="16"
              padding
              style={{ color: "#000124", marginTop: "10px" }}
            >
              Question{" "}
              <QuestionNo>{`${activeQuestions + 1} of ${
                data.length
              }`}</QuestionNo>
            </Bold>
            
            <FlexWrapper noPadding>
              <Bold
                fontWeight="500"
                fontSize="14.5"
                padding
                style={{ color: "#000000", display: "grid", gap: 10, gridTemplateColumns: "50px 1fr", alignItems: "end" }}
              >
                <div
                  style={{
                    color: "#000124",
                    display: "flex",
                    background: "#ddd",
                    width: 40,
                    height: 40,
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%"
                  }}
                >
                  {" "}
                  {activeQuestions + 1}
                </div>
                {data[activeQuestions]}
              </Bold>
            </FlexWrapper>
            <div style={{ margin: 32, padding: 10, marginTop: -20, borderLeft: "4px solid #ddd"}}>
            {isMultiOption[activeQuestions] === false && (
              <TestWrapper>
                <Optoins
                  onClick={(e) => {
                    answerHandler("True");
                    changeHandler();
                    setIsActive({
                      ...isActive,
                      isTrue: !isActive?.isTrue,
                    });
                  }}
                >
                  <OptionButton
                    onClick={(e: any) => {
                      answerHandler("True");
                    }}
                    value="True"
                    active={isActive?.isTrue}
                  >
                    <Option
                      onClick={(e: any) => {
                        answerHandler("True");
                      }}
                    >
                      A
                    </Option>
                    <OptionText>True</OptionText>
                  </OptionButton>
                </Optoins>
                <Optoins
                  onClick={(e) => {
                    answerHandler("False");
                    setIsActive({
                      ...isActive,
                      isFalse: !isActive?.isFalse,
                    });
                  }}
                >
                  <OptionButton
                    onClick={(e: any) => {
                      answerHandler("False");
                    }}
                    value="False"
                    active={isActive?.isFalse}
                  >
                    <Option>B</Option>
                    <OptionText>False</OptionText>
                  </OptionButton>
                </Optoins>
              </TestWrapper>
            )}

            {isMultiOption[activeQuestions] === "Others" && (
              <div style={{ margin: "27px 0 27px 27px"}}>
                <Input
                  width="100%"
                  inputType="textarea"
                  value={Answer}
                  onChange={(value) => {
                    setAnswer(value);
                  }}
                />
              </div>
            )}

            {isMultiOption[activeQuestions] === true && (
              <Row xs={1} md={2} style={{ padding: "0px 0 0 31px", alignItems: "baseline"}}>
                {options?.map((opt, index) => {
                  const isSelected = selectedOption === index;
                  return (
                    <Col key={`${opt.option}-${index}`} Col style={{ padding: "2%" }}>
                      <Optoins
                        onClick={(e) => {
                          answerHandler(opt.option);
                          setSelectedOption(index);
                        }}
                      >
                        <OptionButtonProjectHead
                          onClick={(e: any) => {
                            answerHandler(opt.option);
                          }}
                          value={opt.option}
                          active={isSelected}
                        >
                          <Option>{alphabet[index]}</Option>
                          <OptionTextProjectHead>
                            {opt.option}
                          </OptionTextProjectHead>
                        </OptionButtonProjectHead>
                      </Optoins>
                    </Col>
                  );
                })}
                {show ? (
                  <div style={{ padding: "2%" }}>
                    <div style={{ padding: "2%" }}>
                      <Input
                        width="100%"
                        inputType="textarea"
                        value={otherValues}
                        onChange={(value) => {
                          OtherAnswerHandler(value);
                        }}
                      />
                    </div>
                  </div>
                ) : null}
              </Row>
            )}
            <FlexWrapper justifyContent="right">
              <SubmitButton disabled={Answer?.trim() === ''} onClick={nextClickHandle}>{buttonValue}</SubmitButton>
            </FlexWrapper>
            </div>

          </QuestionContainer>
          <ProgressDivider>
            <ProgessWrapper>
              <Progess
                value={percent}
                text={`${percent}% completed`}
                strokeWidth={6}
                styles={buildStyles({
                  textColor: "#003B0A",
                  textSize: "6px",
                  pathColor: "green",
                  trailColor: "lightgreen",
                  strokeLinecap: "butt",
                })}
              />
            </ProgessWrapper>
          </ProgressDivider>
        </ContentWrapper>
      </WholeWrapper>
    </PageWrapper>
  );
};

