const ROUTES = {
  LOGIN: "/",
  STREAMSELECTION: "/streamSelection",
  TEST: "/test",
  RESULTCOPY: "/result",
  ABOUT: '/about',
  REGISTER: '/register',
  EXISTING_USER: '/existResult'
};

export default ROUTES;
