import styled from "styled-components";

export interface FlexWrapperProps {
  noPadding?: boolean;
  justifyContent?: string;
  height?: number;
  hasBorder?: boolean;
  marginTop?: number;
  marginRight?: number;
  shadow?: boolean;
}

const FlexWrapper = styled.div<FlexWrapperProps>`
  padding: ${({ noPadding }) => (noPadding ? "0" : "8px 0")};
  box-shadow: ${({ shadow }) => (shadow ? "0px 5px 5px lightgray" : "")};
  display: flex;
  flex-wrap: wrap;
  height: ${({ height }) => (height ? `${height}px` : "auto")};
  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent};`}
  margin: ${({ noPadding }) => (noPadding ? "0" : "12px 0px")};
  ${({ hasBorder, theme }) =>
    hasBorder && `border-bottom: 1px solid ${theme.border}`};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}%` : null)};
  margin-right: ${({ marginRight }) =>
    marginRight ? `${marginRight}%` : null};
`;

export default FlexWrapper;
