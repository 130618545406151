import { Button, Dropdown } from "react-bootstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { colors } from "../../const/theme";

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 100;
  background: white;
  width: 100%;
  margin: 0 auto;
  box-shadow: 0px 1px 2px lightgray;
  @media (max-width: 600px) {
    margin: 10px 0px 10px -10px;
    width: 100%;
  }
  @media (max-width: 450px) {
    margin: 10px 0px 10px -10px;
    width: 103%;
  }
`;

export const LogoWrapper = styled(Link)`
  width: 120px;
  height: 35%;
  display: flex;
  margin: auto 6px;
  margin-left: 4%;
  cursor: pointer;
`;

export const Logo = styled.img`
  margin: 0 auto;
  width: 90px;
  height: 35%;
  display: flex;
  margin: auto 12px;
  padding-bottom: 8%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: 4%;
`;

export const MenuContainer = styled.div`
  color: #0f1043;
  text-transform: capitalize;
  opacity: 1;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  margin: 16px 2px;
  font-weight: 700;
`;

export const HamburgerMenu = styled(Button)`
  background: none;
  border: none;
  :hover {
    background: white;
    color: #3335cf;
  }
  font-size: 17px;
  color: #3335cf;
`;

export const ProfileWrapper = styled.div`
  display: flex;
  width: 65%;
  float: right;
  top: 0;
  right: 0;
  margin-left: auto;
  position: relative;
  @media (min-width: 769px) {
    width: 40%;
  }
`;

export const ProfileContainer = styled(Dropdown)`
  margin: auto;
  margin-right: 25px;
  border: 2px solid #3335cf;
  border-radius: 20px;
  width: 150px;
  height: 40px;
  z-index: 100;
  background: none;
  &:focus,
  &:active {
    background: none;
  }
  #profile-dropdown {
    width: 100%;
    color: ${colors.white};
    border: none;
    border-radius: 10px;
    display: flex;
    background: none;
    &:focus,
    &:hover,
    &:active {
      background: none;
      outline: none;
      border: none;
    }
  }
  #profilr-item {
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    font-weight: 500;
  }
  @media (max-width: 415px) {
    z-index: 1;
    margin-right: 11px;
    margin-top: 10px;
    width: 150px;
    height: 40px;
  }
`;
export const ProfileImage = styled.img`
  height: 31px;
  min-width: 31px;
  left: -10px;
  position: relative;
  padding: 0%;
  bottom: 3px;
  overflow: hidden;
  border-radius: 50%;
`;
export const UserName = styled.label`
  color: #ff7b00;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
  margin: auto 0px;
  cursor: pointer;
  @media (max-width: 415px) {
  }
`;

export const ProfileDropDown = styled(Dropdown)`
  margin: auto;
  margin-right: 0;
  #profile-dropdown {
    color: ${colors.white};
    border: none;
    display: flex;
    background: none;
  }
`;
