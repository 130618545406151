export const initialClearValue = {
    name: false,
    lastName: false,
    password: false,
    confirmPasword: false,
    email: false,
    mobile: false,
    country: false,
    state: false,
}

export const Otpreset = {
    otp:false
}