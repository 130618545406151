
import {
  ChangeEvent,
  ReactElement,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import {
  Errorwrapper,
} from "./subcomponents";
import logo from "../../assests/logo.png";

import compassBeforeClick from "../../assests/compassBefore.png";
import compassAfterClick from "../../assests/compassAfter.png";

import {
  ModalDialog,
  LoginHeaderWrapper,
  LoginLogo,
  LoginDialog,
  LockLogo,
  Title,
  BodyWrapper,
  Input,
  FormInput,
  InputBox,
  CheckboxWrapper,
  SubTitle,
  Forgot,
  ModalLoginButton,
  ModalWrapper,
  LoginWrapper,
  HelperText,
} from "./login-subcomponents";
import lock from "../../assests/login.png";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ROUTES from "../../const/routes";
import { useHistory } from "react-router-dom";
import { RootState } from "../../redux/store";
import {
  handleAuthenticate,
  updateHasError,
  updateShowLogin,
} from "../../redux/login/action";
import { RegisterModalDialog } from "./RegisterStyle/subcomponents";
import { FlexWrapper, Loader, ToastMessage } from "../../components";
import { AddUserApi, getCountries } from "../../redux/Register/action";
import {
  OTPBodyWrapper,
  OTPFormInput,
  OTPHeaderWrapper,
  OTPHelperText,
  OTPInput,
  OTPLock,
  OTPLogo,
  OTPModalDialog,
  OTPSubmitButton,
  OTPTitle,
  OTPTitleWrapper,
  OTPVerifyWrapper,
  OTPWrapper,
  ResendButton,
} from "./otp/subcomponent"
import { getStates } from "../../redux/Register/api";
import Register from "./RegisterStyle";
import { getOTP, validateOTP } from "../../redux/streamMaster/api";
import { Otpreset } from "./const";
import { updateHasOTPError, updateHasOTPSented } from "../../redux/streamMaster/action";
import ToastMessageLogin from "../../pages/LandingPage/toast/index";
import { validateRequired } from "../../helpers/formValidation";

const Login = (): ReactElement => {
  const {
    isLoading,
    isLoggedIn,
    registerDetails,
    message,
    hasOtpError = false,
    hasError = false,
    newUser,
    hasGetOtp,
    showLogin,
  } = useSelector(
    (state: RootState) => ({
      isLoading: state.login.isLoading,
      isLoggedIn: state.login.isLoggedIn,
      hasError: state.login.hasError,
      registerDetails: state.register.registerDetails,
      message: state.stream.otp?.message,
      hasOtpError: state.stream.hasOtpError,
      hasGetOtp: state.stream.hasGetOtp,
      newUser: state.login.userInfo?.newUser,
      showLogin: state.login.showLogin,
    }),
    shallowEqual
  );
  const [showRegister, setShowRegister] = useState(false);
  const [showOtp, setShowOtp] = useState<any>("");
  const [values, setValues] = useState(registerDetails);

  const [otpvalues, setOTPValues] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [clearvaluesOTP, setClearValuesOTP] = useState(Otpreset);
  const [errorss, setErrors] = useState('');
  const [errorsspass, setErrorspass] = useState('');
  const [time, setTime] = useState(59);
  const [loginValues, setloginValues] = useState({
    email: '',
    pass: ''
  })
  const dispatch = useDispatch();
  const history = useHistory();

  const Otpclear = () => {
    setClearValuesOTP({
      otp: true,
    });
    setOTPValues("");
  };

  const handleSubmitOTP = () => {
    dispatch(
      validateOTP({
        id: `${message}`,
        otpcode: otpvalues,
      })
    );
    Otpclear();
  };

  useEffect(() => {
    if (message === "Verification Successful" && !isLoggedIn) {
      dispatch(updateShowLogin(true));
      setShowOtp(false);
      dispatch(AddUserApi(values));
      alert("Successful");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  // determine User
 /*  useEffect(() => {
    if (isLoggedIn) {
      if (newUser === false) {
        history.push(ROUTES.EXISTING_USER);
      } else if (newUser === true) {
        history.push(ROUTES.STREAMSELECTION)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]); */

  //getState
  useEffect(() => {
    dispatch(getCountries());
    dispatch(getStates({ id: "101" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setOtpTime = (time: number) => {
    if (time === 0) {
      return
    } else {
      const timeout = setTimeout(() => {
        setTime(time - 1);
      }, 1000);
      return () => clearInterval(timeout);
    }
  }

  // useEffect(() => {
  //   if (hasOtpError) {
  //     setshowTost(true)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [message]);

  //compass
  const [map, showMap] = useState(false);

  const Img = map ? compassAfterClick : compassBeforeClick;

  const canSubmit =
    !!loginValues.email &&
    !!loginValues.pass

  return (
    <>
      {showLogin && !isLoggedIn && (
        <ModalDialog
          show={true}
          onHide={() => dispatch(updateShowLogin(false))}
          centered={true}
          contentclassName="contentName"
          backdrop="true"
          keyboard={false}
        >
          <LoginDialog>
            <LoginHeaderWrapper>
              <LoginLogo src={logo} />
              <LockLogo src={lock} />
            </LoginHeaderWrapper>
            <ModalWrapper>
              <LoginWrapper>
                <Title>Login</Title>
                <HelperText
                  onClick={() => {
                    setShowRegister(true);
                    dispatch(updateShowLogin(false));
                    dispatch(getCountries());
                  }}
                >
                  New user ?<span>Register</span>
                </HelperText>
              </LoginWrapper>
              <BodyWrapper
                onSubmit={(e: SyntheticEvent) => {
                  e.preventDefault();
                  e.stopPropagation()
                  dispatch(handleAuthenticate({
                    email: loginValues.email,
                    password: loginValues.pass
                  }));
                }}
              >
                <Input>
                  <FormInput
                    placeholder="abc@gmail.com"
                    value={loginValues.email}
                    onBlur={() => {
                      const error = validateRequired(loginValues.email)
                      setErrors(error)
                    }}
                    isInvalid={errorss}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setloginValues({
                        ...loginValues,
                        email: event.target.value
                      })
                    }
                    type="text"
                  />
                </Input>
                <Errorwrapper>{errorss ? 'Please Enter Your Registered Mail' : ''}</Errorwrapper>
                <Input>
                  <FormInput
                    placeholder="Password"
                    value={loginValues.pass}
                    onBlur={() => {
                      const error = validateRequired(loginValues.pass)
                      setErrorspass(error)
                    }}
                    isInvalid={errorsspass}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setloginValues({
                        ...loginValues,
                        pass: event.target.value
                      })
                    }
                    type="password"
                  />
                </Input>
                <Errorwrapper>{errorsspass ? 'Please Enter Your Password' : ''}</Errorwrapper>
                <CheckboxWrapper>
                  <InputBox />
                  <SubTitle>Remember me..</SubTitle>
                </CheckboxWrapper>
                <Forgot>Forgot Password</Forgot>
                {isLoading ? (
                  <Loader />
                ) : (
                  <ModalLoginButton disabled={!canSubmit} type="submit">Login</ModalLoginButton>
                )}
              </BodyWrapper>
            </ModalWrapper>
            <ToastMessageLogin
              show={hasError}
              onCloseHandler={() => dispatch(updateHasError(false))}
              message="Login failed. Please check the credentials"
            ></ToastMessageLogin>
          </LoginDialog>
        </ModalDialog>
      )}

      {/* --------------------------------------------registerModal-------------------------------------------------- */}

      {showRegister && (
        <RegisterModalDialog
          isLargeModal={true}
          handleSubmit={() => {
            setShowRegister(false);
          }}
          handleCancel={() => setShowRegister(false)}
        >
          <Register
            values={values}
            setValues={setValues}
            setLogin={(show: boolean) => dispatch(updateShowLogin(show))}
            setRegister={setShowRegister}
            setOtp={setShowOtp}
          />
        </RegisterModalDialog>
      )}

      {/* --------------------------------------------otpModal-------------------------------------------------- */}

      {showOtp && (
        <OTPModalDialog
          show={true}
          onHide={() => setShowOtp("")}
          centered={true}
          dialogClassName="OTPdialog"
          backdrop="true"
          size="sm"
          keyboard={false}
        >
          <OTPWrapper>
            <OTPHeaderWrapper>
              <OTPLogo src={logo} />
              <OTPLock src={lock} />
            </OTPHeaderWrapper>
            <OTPTitleWrapper>
              <ToastMessage
                show={hasOtpError}
                bg='danger'
                top="10px"
                right="-20px"
                onCloseHandler={() => dispatch(updateHasOTPError(false))}
                message="Verfication Failed"
              ></ToastMessage>
              <ToastMessage
                show={hasGetOtp}
                bg='success'
                top="10px"
                right="-20px"
                onCloseHandler={() => {  dispatch(updateHasOTPSented(false)) }}
                message="OTP has been sent on your mobile number successfully"
              ></ToastMessage>
              <OTPVerifyWrapper>
                <OTPTitle>Verify</OTPTitle>
                <OTPHelperText>
                  Resend OTP in <span>{setOtpTime(time)}{time}</span>
                </OTPHelperText>
              </OTPVerifyWrapper>
              <OTPBodyWrapper>
                <OTPInput>
                  <OTPFormInput
                    placeholder="Enter OTP"
                    value={otpvalues}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setOTPValues(e.target.value);
                    }}
                  />
                </OTPInput>
                <FlexWrapper justifyContent="space-between" noPadding >
                  <ResendButton
                    disabled={time === 0 ? false : true}
                    onClick={() => {
                      setTime(59);
                      dispatch(
                        getOTP({
                          number: values?.mobile,
                        })
                      )
                    }}
                  >Resend OTP</ResendButton>
                </FlexWrapper>
                <OTPSubmitButton onClick={handleSubmitOTP}>
                  Verify
                </OTPSubmitButton>
              </OTPBodyWrapper>
            </OTPTitleWrapper>
          </OTPWrapper >
        </OTPModalDialog >
      )}

    </ >
  );
};

export default Login;
