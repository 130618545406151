import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement, useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useDispatch } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { RootState } from "../../redux/store";
import {
  HeaderWrapper,
  HamburgerMenu,
  MenuContainer,
  LoginButton,
  Logo,
  Wrapper,
  MenuList,
  MenuListWrapper,
  ProfileWrapper,
  ProfileContainer,
  ProfileImage,
  UserName,
} from "./subcomponents";
import { updateShowLogin, updateIsLoggedIn } from "../../redux/login/action";
import logo from "../../assests/logo.png";
import user from "../../assests/user.png";
import { useHistory} from "react-router-dom";
import ROUTES from "../../const/routes";
import { Hamburger } from "./hamburg";
import Login from "./Login";

const MainHeader = (): ReactElement => {
  const queryParams = new URLSearchParams(window.location.search);
  const adpagee = queryParams.get('adpage') || 'Careerkompass';
  const ad = queryParams.get('ad') || '46';
  const cm = queryParams.get('cm') || '1272';
 // const [adpage, setAdpage]=useState({adpage: adpagee, ad: ad, cm: cm});

  useEffect(() => {
    if (!adpagee && !ad && !cm){
      return;
    }
   // setAdpage((prevAdpage) => ({ ...prevAdpage, adpage: adpagee, ad: +ad, cm: +cm }));
   if(sessionStorage.getItem("adpage") && ad == "46"){
      return;
   }
    sessionStorage.setItem("adpage", adpagee);
    sessionStorage.setItem("ad", ad);
    sessionStorage.setItem("cm", cm);

    console.log(sessionStorage.getItem("adpage"));
  }, [adpagee, ad, cm]);
  console.log(sessionStorage.getItem("adpage"));
  
  const { isLoggedIn, userName } = useSelector(
    (state: RootState) => ({
      isLoggedIn: state.login.isLoggedIn,
      userName: state.login.userInfo?.user_name,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const [toggleMenu, setToggleMenu] = useState({ style: { display: "none" } });
  const [menuDisplay, setMenuDisplay] = useState({ view: "inline" });

  const history = useHistory();

  const [colors, setColors] = useState({ h: false, a: false });

  const Toggle = () => {
    if (toggleMenu.style.display === "none") {
      setToggleMenu({ style: { display: "inline" } });
      setMenuDisplay({ view: "none" });
    } else {
      setToggleMenu({ style: { display: "none" } });
      setMenuDisplay({ view: "inline" });
    }
  };

  useEffect(() => {
    if (history.location.pathname === ROUTES.LOGIN) {
      setColors({
        ...colors,
        h: true,
      });
    } else if (history.location.pathname === ROUTES.ABOUT) {
      setColors({
        ...colors,
        a: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
    <HeaderWrapper>
      <div>
        <Link to="/">
          <Logo src={logo} alt="logo" />
        </Link>
      </div>
      <Wrapper>
        <ProfileWrapper>
          {isLoggedIn ? (
              <ProfileContainer>
                <Dropdown.Toggle variant="light" id="profile-dropdown">
                  <ProfileImage src={user} />
                  <UserName title={userName}>
                    {userName?.length > 25
                      ? `${userName?.slice(0, 25)}...`
                      : userName}
                  </UserName>
                  <Dropdown.Menu>
                    <Link to="existResult" className="dropdown-item link">
                      Assessment Result
                    </Link>
                    <Dropdown.Item
                      className="link"
                      onClick={() => {
                        dispatch(updateShowLogin(false));
                        dispatch(updateIsLoggedIn(false));
                      }}
                    >
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown.Toggle>
              </ProfileContainer>
          ) : (
            <LoginButton
              className="login"
              onClick={() => {
                dispatch(updateShowLogin(true));
              }}
            >
              Login
            </LoginButton>
          )}
        </ProfileWrapper>
        <ProfileWrapper>
          <MenuListWrapper
            style={{
              display: `${toggleMenu.style.display}`,
            }}
          >
            <MenuList
              onClick={() => {
                history.push(ROUTES.LOGIN);
              }}
              style={
                colors.h
                  ? { color: "#FF7B00", borderBottom: "2px solid #3335CF" }
                  : { color: "" }
              }
            >
              Home
            </MenuList>
            <MenuList
              onClick={() => {
                history.push(ROUTES.ABOUT);
              }}
              style={
                colors.a
                  ? { color: "#FF7B00", borderBottom: "2px solid #3335CF" }
                  : { color: "" }
              }
            >
              About
            </MenuList>
          </MenuListWrapper>
          <MenuContainer style={{ display: `${menuDisplay.view}` }}>
            Menu
          </MenuContainer>
          {(toggleMenu.style.display === "inline" && (
            <Hamburger onClick={Toggle} className="hamburger">
              <span className="line" />
              <span className="line" />
              <span className="line" />
            </Hamburger>
          )) || (
            <HamburgerMenu onClick={Toggle} className="hamburger-menu">
              <FontAwesomeIcon icon={["fas", "bars"]} size="lg" />
            </HamburgerMenu>
          )}
        </ProfileWrapper>
      </Wrapper>
      <Login />
    </HeaderWrapper>
    </>
  );
};

export default MainHeader;
