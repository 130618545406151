import { createAsyncThunk } from "@reduxjs/toolkit";
import apiEndpoints from "../../const/apiEndpoints";
import api from "../../services";
import { TestPayload } from "./types";

export const postAnswer = createAsyncThunk(
  "question/PostAnswers",
  async (requestPayload: TestPayload): Promise<any> => {
    const response = await api.post(apiEndpoints.PostAnswers, requestPayload);
    return response.data;
  }
);

export const getResultbyUser = createAsyncThunk(
  'getResultbyUser',
  async (requestPayload: any): Promise<any> => {
    const response = await api.post(`${apiEndpoints.getResultByuserId}`, requestPayload)
    return response?.data
  }
)