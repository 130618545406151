import { streamSlice } from "./reducer";
import { handleStreamMaster, SelectedStream } from "./api";

const { updateHasError, updateSelectedStream, updateSelectedLevel,updateHasOTPError ,updateHasOTPSented} =
  streamSlice.actions;

export {
  updateHasError,
  handleStreamMaster,
  SelectedStream,
  updateSelectedStream,
  updateSelectedLevel,
  updateHasOTPSented,
  updateHasOTPError
};
