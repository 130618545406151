import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import Background from "../../assests/testHeader.svg";

const BannerWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 130px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #3335cf;
  min-height: 110px;
  font-size: 14px;
  font-family: "Montserrat",sans-serif;
  font-weight: 700;
  background: url(${Background});
  background-position: center;
  background-size: 1000px;
  background-color: #fff2e1;
  padding: 16px;
  gap: 20px;

  @media screen and (min-width: 650px) {
    grid-template-columns: auto 200px;
    padding: 16px 10%;
    font-size: 20px;
  }
`;

const Button = styled.button`
  background: #3335cf;
  border: 0;
  border-radius: 5px;
  color: white;
  padding: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;

  @media screen and (min-width: 650px) {
    padding: 10px 20px;
    gap: 20px;
  }
`;

const Image = styled.img`
  width: 25px;
  @media screen and (min-width: 650px) {
    width: 35px;
  }
`;

interface PageBannerProps {
  heading: string;
  testName: string;
  level?: string;
  id: number;
}


interface ObjectLiteral {
  [key: string]: string;
}

const useStreamIcon = (id: number) => {
  const [icon, setIcon] = useState<any>(null);

  const iconMap: ObjectLiteral = {
    26: "evHover.svg",
    27: "structuralHover.svg",
    28: "itHover.svg",
    50: "dataHover.svg",
    51: "aero.svg",
    52: "productHover.svg",
    53: "automotive.svg",
    54: "archHover.svg",
    55: "civilHover.svg",
    56: "transHover.svg",
    58: "evHover.svg",
  }

  async function loadMyModule() {
    const {default: img} = await import(`../../assests/${iconMap[id.toString()]}`);
    setIcon(img);
  }

  useEffect(() => {
    loadMyModule();
  }, [id]);


  return icon;
};

const PageBanner = ({ heading, testName, level, id}: PageBannerProps) => {
  const icon = useStreamIcon(id);

  return (
    <BannerWrapper>
      <div>
        {heading}
      </div>
      <Button
        id="logoWrapper"
      >
        <Image src={icon} />
        {testName} {level ? `- ${level}` : ''}
      </Button>
    </BannerWrapper>
  );
}



export default PageBanner;