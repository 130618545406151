import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getLevel, getOTP, GetQuestionByTest, handleStreamMaster, SelectedStream, validateEmail, validateOTP } from "./api";
import {
  CreatetestByStream,
  emailRes,
  GetLevel,
  GetTest,
  InitialState,
  optResponse,
  QuestionType,
  SelectedLevel,
  StreamList,
} from "./types";

const initialState: InitialState = {
  isLoading: false,
  streamMaster: [],
  selectStream: {
    streamID: "",
  },
  questions: [],
  getTest: [],
  levelsData: [],
  uselectedStream: {
    streamID: "",
  },
  testID: {
    testID: "",
  },
  level: {
    test_level: "",
  },
  otp: {
    message: '',
    success: ''
  },
  emailval: {
    message: '',
    success: ''
  },
  hasOtpError: false,
  hasGetOtp: false
};
export const streamSlice = createSlice({
  name: "streamMaster",
  initialState,
  reducers: {
    updateSelectedStream: (
      state,
      action: PayloadAction<CreatetestByStream>
    ) => {
      state.uselectedStream = action?.payload;
    },
    updateHasError: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    updateSelectedLevel: (state, action: PayloadAction<SelectedLevel>) => {
      state.level = action?.payload;
    },
    updateHasOTPError: (state, action: PayloadAction<boolean>) => {
      state.hasOtpError = action.payload;
    },
    updateHasOTPSented: (state, action: PayloadAction<boolean>) => {
      state.hasGetOtp = action.payload;
    }
  },
  extraReducers: {
    [handleStreamMaster.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [handleStreamMaster.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<StreamList>>
    ) => {
      state.isLoading = false;
      state.streamMaster = action?.payload;
    },
    [handleStreamMaster.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [SelectedStream.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [SelectedStream.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetTest>>
    ) => {
      state.isLoading = false;
      state.getTest = action?.payload;
    },
    [SelectedStream.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [GetQuestionByTest.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [GetQuestionByTest.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<QuestionType>>
    ) => {
      state.isLoading = false;
      state.questions = action?.payload;
    },
    [GetQuestionByTest.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [getLevel.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getLevel.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetLevel>>
    ) => {
      state.isLoading = false;
      state.levelsData = action?.payload;
    },
    [getLevel.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [getOTP.fulfilled.toString()]: (
      state,
      action: PayloadAction<optResponse>
    ) => {
      state.otp = action.payload
      state.hasGetOtp = true
    },
    [validateOTP.pending.toString()]: (state) => {
      state.hasOtpError = false;
    },
    [validateOTP.fulfilled.toString()]: (
      state,
      action: PayloadAction<optResponse>
    ) => {
      state.otp = action.payload
      state.hasOtpError = false;
    },
    [validateOTP.rejected.toString()]: (state) => {
      state.hasOtpError = true;
    },
    [validateEmail.fulfilled.toString()]: (
      state,
      action: PayloadAction<emailRes>
    ) => {
      state.emailval = action.payload
    },
  },
});

export default streamSlice.reducer;
