import { ReactElement } from "react";
import { Toast, CloseButton } from "react-bootstrap";
import { Small } from "../../typography";
import styled from "styled-components";
import { colors } from "../../const/theme";

interface ToastProps {
  top?: string
  right?: string
}

const ToastWrapper = styled(Toast) <ToastProps>`
  margin: auto;
  position: absolute; 
  top: ${({ top }) => (top ? `${top}` : '15px')};
  right: 50%;
  translate: 50%;
`;

const Message = styled(Toast.Body)`
  display: flex;
  justify-content: space-between;
`;

interface TostMessageProps {
  show: boolean;
  onCloseHandler?: () => void;
  message: string;
  bg: string;
  top?: string
  right?: string
}

const ToastMessageLogin = ({
  show,
  onCloseHandler,
  message,
  bg,
  top,
  right
}: TostMessageProps): ReactElement => {
  return (
    <ToastWrapper
      show={show}
      bg={bg}
      autohide
      delay={3000}
      onClose={onCloseHandler}
      top={top}
      right={right}
    >
      <Message>
        <Small color={colors.white}>{message}</Small>
        {onCloseHandler && (
          <CloseButton onClick={onCloseHandler} variant="white" />
        )}
      </Message>
    </ToastWrapper>
  );
};

export default ToastMessageLogin;
