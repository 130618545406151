import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faMobile,
  faUser,
  faLock,
  faPen,
  faMobileAlt,
  faBars,
  faCogs,
  faTrophy,
  faUserCog,
  faFolder,
  faChevronDown,
  faChevronRight, 
  faTachometerAlt,
  faUserPlus,
  faInfoCircle,
  faDonate,
  faUserTag,
  faNewspaper,
  faUserCircle,
  faBell,
  faSearch,
  faMedal,
  faLineChart,
  faTools,
  faBook,
  faDownload,
  faChevronLeft
} from "@fortawesome/free-solid-svg-icons";

import {
  faStickyNote,
  faTrashAlt,
  faEdit,
  faEyeSlash,
  faEye,
  faFileAlt,
} from "@fortawesome/free-regular-svg-icons";

import {
  faFacebook,
  faLinkedin,
  faTwitter
} from '@fortawesome/free-brands-svg-icons'

library.add(
  faMobile,
  faUser,
  faLock,
  faMobileAlt,
  faBars,
  faCogs,
  faTrophy,
  faStickyNote,
  faUserCog,
  faFolder,
  faChevronDown,
  faChevronRight,
  faTachometerAlt,
  faUserPlus,
  faInfoCircle,
  faTrashAlt,
  faEdit,
  faEyeSlash,
  faLineChart,
  faPen,
  faEye,
  faDonate,
  faUserTag,
  faNewspaper,
  faChevronLeft,
  faUserCircle,
  faFileAlt,
  faBell,
  faSearch,
  faMedal,
  faTools,
  faBook,
  faDownload,
  faFacebook,
  faTwitter,
  faLinkedin
);
