/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ChangeEvent,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditableDropdown, FlexWrapper } from "../../../components";
import { DropdownListProps } from "../../../components/EditableDropdown/typings";
import {
  getCountryDropdown,
  getStateDropdown,
} from "../../../helpers/dropdown";
import {
  AddUserApi,
  getCountryPin,
  getProfile,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getCountries,
  getStates,
} from "../../../redux/Register/api";
import { RootState } from "../../../redux/store";
import {
  RegisterWrapper,
  RegisterHeaderWrapper,
  RegisterLock,
  RegisterLogo,
  RegisterBodyWrapper,
  RegisterFormInput,
  RegisterHelperText,
  RegisterInput,
  RegisterModalWrapper,
  RegisterTitle,
  RegisterTitleWrapper,
  ModalRegisterButton,
  Span,
  FormFeedback,
  ErrorWrapper,
  RegisterDropDownWrapper,
} from "./subcomponents";
import logo from "../../../assests/logo.png";
import lock from "../../../assests/login.png";
import {
  OTPModalDialog,
  OTPBodyWrapper,
  OTPFormInput,
  OTPHeaderWrapper,
  OTPHelperText,
  OTPInput,
  OTPLock,
  OTPLogo,
  OTPSubmitButton,
  OTPTitle,
  OTPTitleWrapper,
  OTPVerifyWrapper,
  OTPWrapper,
  ResendButton,
} from "../otp/subcomponent";
import { getOTP, validateEmail } from "../../../redux/streamMaster/api";
import validateRegistration from "./helper";
import { hasFormError } from "../../../helpers/formValidation";
import { RegisterField } from "./typing";
import { DropdownWrapper } from "./RDP/subcomponents";
import ReactDropdown from "./RDP";
import { isDeepStrictEqual } from "util";
import { updateHasError } from "../../../redux/login/action";
import ToastMessageLogin from "../../../components/ToastMessge";

interface RegisterProps {
  values: any;
  //eslint-disable-next-line no-unused-vars
  setValues: (formValues: any) => void;
  setLogin: any;
  setRegister: any;
  setOtp: any;
}

const Register = ({
  values,
  setValues,
  setLogin,
  setRegister,
  setOtp
}: RegisterProps): ReactElement => {
  const {
    countryList,
    stateList,
    countryPin,
    message, success, 
  } = useSelector(
    (state: RootState) => ({
      countryList: state.register.countryData,
      stateList: state.register.stateData,
      countryPin: state.register.countryPin,
      message: state.stream.emailval?.message,
      success: state.stream.emailval?.success,
       
    })
  );

  const CountyDropdown = countryList ? getCountryDropdown(countryList) : [];
  const StateDropDown = stateList ? getStateDropdown(stateList) : [];

  const [errors, setErrors] = useState({} as Record<string, string>);
  const [pin, setPin] = useState("+91");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [counter, setCounter] = useState(59);

  const dispatch = useDispatch();


  const validateBlur = (fields: RegisterField) => {
    setErrors(
      validateRegistration({
        values,
        errors,
        fields,
      })
    );
  };

  const handleRegisterSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    const validationError = validateRegistration({
      values,
      errors,
    });
    if (hasFormError(validationError)) {
      setErrors(validationError);
    } else {
      if (message === 'Success') {
        dispatch(
          getOTP({
            number: values?.mobile,
          })
        );
        setRegister(false);
        setOtp(values?.mobile);
      }
    }
  };

  const mailValidation = message !== 'Success'

  useEffect(() => {
    if (values?.country === 'India') {
      setValues({
        ...values,
        country: '+91'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.country])

  useEffect(() => {
   // console.log("Hiii");
      setValues({
        ...values,
        adpage: sessionStorage.getItem("adpage"),
        ad: sessionStorage.getItem("ad"),
        cm: sessionStorage.getItem("cm"),
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStorage.getItem("adpage")])


  
  useEffect(() => {
    setPin(countryPin.map((x: any) => x.contact_no_code)[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryPin]);

  return (
    <>
      <RegisterWrapper>
        <RegisterHeaderWrapper>
          <RegisterLogo src={logo} />
          <RegisterLock src={lock} />
        </RegisterHeaderWrapper>
        <RegisterModalWrapper>
          <RegisterTitleWrapper>
            <RegisterTitle>Register</RegisterTitle>
            <RegisterHelperText
              onClick={() => {
                setLogin(true);
                setRegister(false);
              }}
            >
              Have an account ? <span>Login</span>
            </RegisterHelperText>
          </RegisterTitleWrapper>
          <RegisterBodyWrapper onSubmit={handleRegisterSubmit}>
            <FlexWrapper justifyContent="space-around">
              <RegisterInput>
                <RegisterFormInput
                  placeholder="First Name"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setValues({ ...values, name: e.target.value, adpage: sessionStorage.getItem("adpage"), adpage_id: sessionStorage.getItem("ad"),campaign_id : sessionStorage.getItem("cm") })
                  }
                  maxlength="25"
                  type="text"
                  value={values?.name}
                  onBlur={() => validateBlur("name")}
                  isInvalid={!!errors.name}
                  autoComplete="off"
                />
                <FormFeedback type="invalid">{errors.name === 'Username is required' ? 'Firstname is required' : 'Maximum 25 Character is Allowed'}</FormFeedback>
              </RegisterInput>

              
              <RegisterInput>
                <RegisterFormInput
                  placeholder="Last Name"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setValues({ ...values, lastName: e.target.value })
                  }
                  maxlength="25"
                  value={values?.lastName}
                  type="text"
                  onBlur={() => validateBlur("lastName")}
                  isInvalid={!!errors.lastName}
                  autoComplete="off"
                />
                <FormFeedback type="invalid">{errors.lastName === 'Username is required' ? 'Lastname is required' : 'Maximum 25 Character is Allowed'}</FormFeedback>
              </RegisterInput>
              <RegisterInput>
                <RegisterFormInput
                  placeholder="Password"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setValues({ ...values, password: e.target.value })
                  }
                  value={values?.password}
                  type="password"
                  onBlur={() => validateBlur("password")}
                  isInvalid={!!errors.password}
                  autoComplete="new-password"
                />
                <FormFeedback type="invalid">{errors.password}</FormFeedback>
              </RegisterInput>
              <RegisterInput>
                <RegisterFormInput
                  placeholder="Confirm Password"
                  value={values?.confirmPassword}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setValues({ ...values, confirmPassword: e.target.value })
                  }
                  type="password"
                  onBlur={() => validateBlur("confirmPassword")}
                  isInvalid={!!errors.confirmPassword}
                />
                <FormFeedback type="invalid">
                  {errors.confirmPassword}
                </FormFeedback>
              </RegisterInput>
              {message === 'Email Already Registered' ? (
                <ErrorWrapper>User already registered! </ErrorWrapper>
              ) : ''}
              <RegisterInput>
                <RegisterFormInput
                  placeholder="E-mail"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setValues({ ...values, email: e.target.value })
                  }
                  onBlur={() => {
                    validateBlur('email')
                    dispatch(
                      validateEmail({
                        email: values?.email
                      })
                    )
                  }
                  }
                  type="text"
                  value={values?.email}
                  isInvalid={!!errors.email}
                  autocomplete="new-password"
                />
                <FormFeedback type="invalid">{errors.email}</FormFeedback>
              </RegisterInput>
              <RegisterDropDownWrapper>
                <EditableDropdown
                  dropdownList={CountyDropdown}
                  placeholder={"Select Countries"}
                  defaultValue={{
                    id: CountyDropdown[100].id,
                    name: CountyDropdown[100].name
                  }}
                  handleSelect={(value: DropdownListProps) => {
                    setValues({
                      ...values,
                      country: value?.countryCode
                    }); 
                    dispatch(
                      getStates({
                        id: value?.id,
                      })
                    );
                    dispatch(getCountryPin({ id: value?.id }));
                  }}
                />
              </RegisterDropDownWrapper>
              <RegisterDropDownWrapper>
                <ReactDropdown
                  dropdownList={StateDropDown}
                  placeholder={"Select State"}
                  singleSelect
                  handleSelect={(value) => {
                    const ids = value?.map((item: DropdownListProps) => item?.id)
                    setValues({ ...values, state: `${ids}` });
                  }}
                />
              </RegisterDropDownWrapper>
              <RegisterInput>
                <Span>{values.country === "+91" ? "+91" : pin}</Span>
                <RegisterFormInput
                  placeholder="Mobile No"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setValues({ ...values, mobile: e.target.value })
                  }
                  type="text"
                  value={values?.mobile}
                  onBlur={() => validateBlur("mobile")}
                  isInvalid={!!errors.mobile}
                />
                <FormFeedback type="invalid">{errors.mobile}</FormFeedback>
              </RegisterInput>
            </FlexWrapper>
            <FlexWrapper justifyContent="center" noPadding>
              <ModalRegisterButton type="submit">Next</ModalRegisterButton>
            </FlexWrapper>
          </RegisterBodyWrapper>
        </RegisterModalWrapper>
      </RegisterWrapper>
      {/* {showOtp && (
        <OTPModalDialog
          show={true}
          onHide={() => setShowOtp(false)}
          centered={true}
          dialogClassName="OTPdialog"
          backdrop="false"
          size="sm"
          keyboard={false}
        >
          <OTPWrapper>
            <OTPHeaderWrapper>
              <OTPLogo src={logo} />
              <OTPLock src={lock} />
            </OTPHeaderWrapper>
            <OTPTitleWrapper>
              <OTPVerifyWrapper>
                <OTPTitle>Verify</OTPTitle>
                <OTPHelperText>
                  Resend OTP in <span>00:{counter}</span>
                </OTPHelperText>
              </OTPVerifyWrapper>
              <OTPBodyWrapper>
                <OTPInput>
                  <OTPFormInput placeholder="Enter OTP" />
                </OTPInput>
                <ResendButton>Resend OTP</ResendButton>
                <OTPSubmitButton
                  onClick={() => {
                    setLogin(true);
                    setShowOtp(false);
                  }}
                >
                  Register
                </OTPSubmitButton>
              </OTPBodyWrapper>
            </OTPTitleWrapper>
          </OTPWrapper>
        </OTPModalDialog>
      )} */}
    </>
  );
};

export default Register;
