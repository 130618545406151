export interface AuthenticatePayload {
  loginDevice: "mobile" | "website";
}

export interface UserInfo {
  last_name: string;
  message: string;
  newUser: boolean;
  success: boolean;
  token: any;
  userId: string;
  user_name: any;
}

interface UserState {
  isLoggedIn: boolean;
  isLoading: boolean;
  email: string;
  password: string;
  hasError: boolean;
  userInfo: UserInfo | null;
  showLogin: boolean;
}
export const initialState: UserState = {
  isLoggedIn: false,
  isLoading: false,
  email: "",
  password: "",
  hasError: false,
  userInfo: null,
  showLogin: false,
  
};

 