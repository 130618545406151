import styled from "styled-components";
import { InputGroup, FormControl } from "react-bootstrap";
import { colors } from "../../const/theme";
import { EditDropdownWrapperProps } from "./typings";
import { H3 } from "../../typography";

export const ListInput = styled(InputGroup) <EditDropdownWrapperProps>`
  height: 43%;
  #input {
    font-size: 12px;
    font-weight: 600;
  }
  #dropdown-id {
    border: none;
    background-color: white;
    &:hover,
    &:focus {
      background: white;
      color: #9b8f8f;
    }
  }
  .dropdown-menu.show {
    max-height: 300px;
    font-family: 'Montserrat', sans-serif; 
    overflow: auto;
    font-weight: 600;
    transform: translate(0, 40px) !important;
  }
`;

export const Title = styled(H3)`
  margin-bottom: 5px;
`;

export const DropdownInput = styled(FormControl)`
  border: none;
  ::placeholder {
    color: #26262c;
    text-transform: capitalize;
    font-weight: 500; 
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;  
  }
  
  background: ${colors.white} !important;

  &.is-valid + #dropdown-id {
    border-color: green;
  }

  &.is-invalid + #dropdown-id {
    border-color: red;
  }
`;

export const EditDropdownWrapper = styled.div<EditDropdownWrapperProps>`
  width: ${({ width }) => width || "100%"};
  @media (max-width: 415px) {
    width: 100%;
  }

  ${({ isDisabled }) =>
    isDisabled &&
    `
pointer-events: none;
opacity: 0.7;
`}
`;

export const FormFeedback = styled(FormControl.Feedback)` 
font-size: 10px;
font-family: 'Montserrat', sans-serif; 
font-weight: 700;
`