import { ReactElement, useEffect, useRef } from "react";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { useDispatch } from "react-redux";
import theme, { colors } from "./const/theme";
import { Router } from "react-router-dom";
import styled from "styled-components";
import history from "./const/history";
import Routes from "./routes";
import BREAKPOINTS from "./const/breakpoint";
import useBreakpoint from "use-breakpoint";
import { updateIsLoggedIn, updateUserInfo } from "./redux/login/action";
import { UserInfo } from "./redux/login/types";
import "./App.css";

const Body = styled.div`
  display: flex;
  min-height: calc(100vh - 40px);
`;

export const GlobalStyles = createGlobalStyle`
  .input-group-text {
    background: none;
    border: none;
  }
  td {
    border-bottom: 1px solid ${colors.lightGrey};
    text-align: center;
    font-weight:500;
  }
  th {
    border: none;
    text-align: center;
  }
  .btn-link {
    color: ${colors.purple};
  }
  .btn-link:hover {
    color: ${colors.lightGrey};
  }
  .btn:focus {
    box-shadow: none;
  }
  .react-datepicker-wrapper {
    .form-control.is-valid {
        border-color: ${colors.purple};
    }
  }
`;

const App = (): ReactElement => {
  const checkLoginState = useRef(false);
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("Authorization");
  if (!checkLoginState.current) {
    if (token) {
      const userInfo: UserInfo = {
        userId: sessionStorage.getItem("userId") || "",
        user_name: sessionStorage.getItem("user_name"),
        last_name: sessionStorage.getItem("last_name") || "",
        message: "login successfully",
        newUser: false,
        success: true,
        token,
      };
      checkLoginState.current = true;
      dispatch(updateUserInfo(userInfo));
      dispatch(updateIsLoggedIn(true));
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [breakpoint]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Router history={history}>
        <Body>
          <Routes />
        </Body>
      </Router>
    </ThemeProvider>
  );
};

export default App;
