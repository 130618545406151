import { Loader } from "../../components";
import {
  AboutTitle,
  AboutImage,
  Logo,
  PageWrapper,
} from "../AboutPage/subcomponents";
import lock from "../../assests/login.png";
import Ab from "../../assests/Group.png";
import logo2 from "../../assests/footerLogo.svg";
import logo from "../../assests/about.png";
import {
  ChangeEvent,
  ReactElement,
  SyntheticEvent,
  useState,
} from "react";
import MainHeader from "../../components/Header";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";

import { useHistory } from "react-router-dom";
import FbRegular from "../../assests/FbRegular.png";
import FbSolid from "../../assests/FbSolid.png";
import LinkedRegular from "../../assests/LinkedRegular.png";
import LinkedSolid from "../../assests/LinkedSolid.png";
import TwitterRegular from "../../assests/TwitterRegular.png";
import TwitterSolid from "../../assests/TwitterSolid.png";
import {
  FooterIcon,
  PrivacyContainer,
  BrandIcon,
  WrapperC4,
  Logo1,
} from "../LandingPage/subcomponents";

const AboutPage = (): ReactElement => {
  const {
    login: { isLoggedIn },
  } = useSelector(
    (state: RootState) => ({
      registerDetails: state.register.registerDetails,
      isLoading: state.login.isLoading,
      login: state.login,
      message: state.stream.otp?.message,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const [valuesIcon, setValuesIcon] = useState({
    FB: FbRegular,
    Twitter: TwitterRegular,
    Linked: LinkedRegular,
  });


  return (
    <PageWrapper>
      <MainHeader />
      <div className="title">
        <AboutTitle>About</AboutTitle>
      </div>
      <div className="content-wrapper">
        <div className="image-wrapper">
          <AboutImage src={Ab} />
        </div>
        <div className="about-content">
          <p>
            Transforming education for millions of aspirants with various
            learning modes and innovative teaching techniques, Cloudkampus has
            arrived as a reputed global online institution, settingaworld-class
            learning environment.
          </p>
          <p>
            With well-designed study materials, specially curated courses, and
            certifications to develop in-demand technology, design, and
            management skills, Cloudkampus aims to goastep beyond and
            revolutionize the practice of career decision-making with
            Careerkompass's App.
          </p>
          <p>
            Careerkompass helps students and professionals steer their course
            towards finding a scientific approach to making crucial work
            decisions. Building on self-exploration, strength identification,
            and maximizing inputs for a successful future, Careerkompass
            empowers students to establish possible success and fulfilment with
            different career options based on their personality and skills.
            Along with assessment and matching preferences, Careerkampus also
            plotsacourse for the future with two different levels, one for
            beginners and another for experienced individuals.
          </p>
        </div>
        <div className="logo-wrapper">
          <Logo src={logo} />
        </div>
      </div>
      
      <PrivacyContainer>
        <WrapperC4>
          <div>
            <a
              href="https://www.cloudkampus.com/"
              target="_blank"
              rel="noreferrer noopener"
              className="link"
            >
              <Logo1 src={logo2} alt="Cloud Kampus" />
            </a>
          </div>
          <BrandIcon>
            <a
              href="https://www.facebook.com/Cloudkampus/"
              target="_blank"
              rel="noreferrer noopener"
              className="link"
            >
              <FooterIcon
                src={valuesIcon.FB}
                onMouseOver={() => {
                  setValuesIcon({ ...valuesIcon, FB: FbSolid });
                }}
                onMouseOut={() => {
                  setValuesIcon({ ...valuesIcon, FB: FbRegular });
                }}
              />
            </a>
            <a
              href="https://twitter.com/cloudkampus1"
              target="_blank"
              rel="noreferrer noopener"
              className="link"
            >
              <FooterIcon
                src={valuesIcon.Twitter}
                onMouseOver={() => {
                  setValuesIcon({ ...valuesIcon, Twitter: TwitterSolid });
                }}
                onMouseOut={() => {
                  setValuesIcon({ ...valuesIcon, Twitter: TwitterRegular });
                }}
              />
            </a>
            <a
              href="https://www.linkedin.com/company/cloudkampus"
              target="_blank"
              rel="noreferrer noopener"
              className="link"
            >
              <FooterIcon
                src={valuesIcon.Linked}
                onMouseOver={() => {
                  setValuesIcon({ ...valuesIcon, Linked: LinkedSolid });
                }}
                onMouseOut={() => {
                  setValuesIcon({ ...valuesIcon, Linked: LinkedRegular });
                }}
              />
            </a>
          </BrandIcon>
        </WrapperC4>
      </PrivacyContainer>
    </PageWrapper>
  );
};

export default AboutPage;
