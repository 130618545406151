import { Button, Card, Figure, FormControl } from "react-bootstrap";
import styled from "styled-components";

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .title {
    margin: 112px 0 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left:-150px !important;
  }
  .content-wrapper {
    display: flex;
    padding: 12px;
    gap: 10px;
    margin-bottom: 16px;
    .image-wrapper {
      flex: 40%;
    }
    .about-content {
      flex: 50%;
    }
    .logo-wrapper {
      display: flex;
      flex: 10%;
      align-items: end;
      height: calc(100vh - 240px);
    }
    @media (max-width: 600px) {
      flex-direction: column;
    }
  }
`;

export const FlexWrap = styled.div`
  justify-content: space-around;
  width: 100%;
  height: 110px;
  #logoWrapper {
    display: row;
    flex-direction: row;
  }
`;

export const AboutTitle = styled.h5`
  color: #0f1043;
  font-size: 33px;
  font-weight: 750;
  font-family: "Montserrat", sans-serif;
`;

export const AboutImage = styled(Figure.Image)`
  width: 100%; 
  position: relative;
  left: 0px;
  object-fit: cover;
`;

export const Para = styled.p`
  font-family: "Montserrat", sans-serif;
  font-weight: 450;
  font-size: 15px;
  width: 50%;
  color: ${({ color }) => (color ? color : "#000000")};
  margin: 3% 3%;
  @media (max-width: 600px) {
    margin: 0 auto;
    font-size: 9px;
    width: auto;
  }
`;

export const Logo = styled.img`
  width: 70px;
  position: relative;
  height: 180px;
  @media (max-width: 650px) {
    display: none;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: right;
  position: relative;
  z-index: 100;
  margin-top: -12%;
  @media (max-width: 900px) {
     
  }
`;

export const FinalWrapper = styled.div``;

export const Container4 = styled.div`
  height: 100px;
  position: relative;
  background-color: #fcfcff;
  @media (max-width: 580px) {
    width: 100%;
    height: 40px;
  }
`;

export const FormCardWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
`;

export const FormCard = styled(Card)`
  display: flex;
  justify-content: space-around;
  z-index: 1000;
  margin-top: 3rem;
  border-radius: 10px;
  background-color: rgb(51, 53, 207);
  width: 50%;
  height: 110px;
  @media (max-width: 415px) {
    width: 85%;
    margin-top: -3%;
  }
`;

export const FormCardContent = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const FormCardText = styled(Card.Body)`
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  color: white;
  margin-right: 100px;
  @media (max-width: 800px) {
    font-size: 12px;
  }
`;

export const FormCardInput = styled(FormControl)`
  height: 40px;
  width: 40%;
  margin-top: 10px;
  margin-right: 25px;
  border-radius: 20px;
  border: none;
  ::placeholder {
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 550;
  }
  @media (max-width: 650px) {
    width: 60%;
    height: 50%;
  }
`;

export const FormCardButton = styled.button`
  z-index: 1000;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  position: absolute;
  right: 40px;
  top: 42px;
  height: 30px;
  width: 11%;
  border: none;
  font-size: 12px;
  border-radius: 20px;
  background-color: rgb(255, 123, 0);
  color: rgb(255, 242, 230);
  @media (max-width: 800px) {
    font-size: 10px;
    margin-right: 7%;
    margin-top: 5%;
    height: 20px;
    width: 15%;
  }
`;

export const PrivacyContainer = styled.div`
  background-color: #ebebf8;
  min-height: 150px;
  @media (max-width: 580px) {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }

  @media (max-width: 900px) {
    width: auto;
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  padding-top: 75px;
`;

export const Logo1 = styled.img`
  width: 45%;
  margin-left: 28%; 
`;

export const BrandIcon = styled(Button)`
  background: none;
  font-size: 15px;
  border: none;
  position: relative;
  right: 70px;
  color: #0c0ce7;
  &:hover {
    background: none;
  }
`;

export const FHeader = styled.div`
  padding: 10px;
  color: black;
  cursor: pointer;
  font-weight: 800;
  font-size: 13px;
`;
export const Links = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  color: rgba(138, 138, 138, 1);
  font-weight: 600;
  font-size: 13px;
`;

export const FinalFooter = styled.div`
  font-size: 11px;
  padding: 6px 60px; 
  color: rgba(103, 114, 148, 1);
  text-transform: capitalize; 
  justify-content: space-between;
  display: flex;
  font-family: "Montserrat", sans-serif; 
  cursor: pointer;
  background: #ebebf8;
  #footer-text {
    color: red;
  }
  @media (max-width: 600px) {
    padding: 6px 15px;
    margin-top: -0px;
    display: flex;
    justify-content: center;
  }
`;
