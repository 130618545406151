import { Modal } from 'react-bootstrap'
import styled from "styled-components";

export const BootstrapModal = styled(Modal)`
  display: flex !important;
  .modal-content {
    border: none;
    border-radius: 20px !important;
  }
`;
