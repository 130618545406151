import { ReactElement, useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { QuestionSection } from "./subcomponents";
import history from "../../const/history";
import ROUTES from "../../const/routes";

const TestPage = (): ReactElement => {
  const { questionList, login: { isLoggedIn }, newUser } = useSelector(
    (state: RootState) => ({
      questionList: state.stream.questions,
      login: state.login,
      newUser: state.login.userInfo?.newUser,
    }),
    shallowEqual
  );
  const [activeQuestions, setActiveQuestion] = useState(0);
  const questionData = questionList.map((question) => question.question);
  const questionNo = questionList.map((question) => question.question_ID);
  const isMultiOption = questionList.map((question) => question.ismultioption);
  const Options = questionList.map((question) => question.multioption);

  const _backConfirm = async () => {
    let event = window.confirm("Are you sure you want to leave?");
    if(event){
        history.push(ROUTES.STREAMSELECTION);
    }
}

  const _confirm = (e:any) => {
    var confirmationMessage = "\o/";
    e.returnValue = confirmationMessage;
    return confirmationMessage;
  }

  useEffect(() => {
    window.addEventListener("beforeunload", _confirm);
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = _backConfirm;

    return () => {
      window.removeEventListener("beforeunload", _confirm);
      window.onpopstate = () => { }
    }
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      history.push(ROUTES.LOGIN);
    }
    // if (isLoggedIn) {
    //   if (newUser){
    //     history.push(ROUTES.STREAMSELECTION);
    //   }
    //   else {
    //     history.push(ROUTES.EXISTING_USER); 
    //   }
    // }
  }, [isLoggedIn]);
  

  return (
    <QuestionSection
      data={questionData}
      quesId={questionNo}
      activeQuestions={activeQuestions}
      onSetActiveQuestion={setActiveQuestion}
      isMultiOption={isMultiOption}
      options={Options[activeQuestions]}
    />
  );
};

export default TestPage;
