const fonts = {
  tiny: 14,
  small: 18,
  medium: 20,
  large: 22,
  xLarge: 28,
};

export const weight = {
  normal: 400,
  bold: 600,
  xBold: 800
}

export default fonts
