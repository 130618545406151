import { ReactElement, useState } from "react";
import {
  Container4,
  Details4,
  Header4,
  Body4,
  GCard,
  Image,
  CardWrapper1,
  PrivacyContainer,
  Logo1,
  FirstLabels,
  LastLabels,
  Labels,
  CardRow,
  BrandIcon,
  CopyRight,
  FooterIcon,
  BackImageC4,
  LogoWrap,
  WrapperC4,
} from "../subcomponents";
import logo2 from "../../../assests/footerLogo.svg";
import product from "../../../assests/product design.svg";
import structural from "../../../assests/structural.svg";
import ev from "../../../assests/evTech.svg";
import auto from "../../../assests/automotive.svg";
import aero from "../../../assests/aero.svg";
import data from "../../../assests/data science.svg";
import trans from "../../../assests/Group 2540.svg";
import civil from "../../../assests/Group 41962.svg";
import arch from "../../../assests/Group 41946.svg";
import ic from "../../../assests/Group 41999.svg";
import { Col } from "react-bootstrap";
import productRegular from "../../../assests/productHover.svg";
import structuralRegular from "../../../assests/structuralHover.svg";
import evRegular from "../../../assests/evHover.svg";
import dataRegular from "../../../assests/dataHover.svg";
import tranRegular from "../../../assests/transHover.svg";
import civilRegular from "../../../assests/civilHover.svg";
import archRegular from "../../../assests/archHover.svg";
import itcsRegular from "../../../assests/itHover.svg";
import FbRegular from "../../../assests/FbRegular.png";
import FbSolid from "../../../assests/FbSolid.png";
import LinkedRegular from "../../../assests/LinkedRegular.png";
import LinkedSolid from "../../../assests/LinkedSolid.png";
import TwitterRegular from "../../../assests/TwitterRegular.png";
import TwitterSolid from "../../../assests/TwitterSolid.png";
import BackImageC04 from "../../../assests/about.png";

const Footers = (): ReactElement => {
  const [values, setValues] = useState({
    product: product,
    structural: structural,
    ev: ev,
    aero: aero,
    data: data,
    transport: trans,
    civil: civil,
    arch: arch,
    itcs: ic,
    FB: FbRegular,
    Twitter: TwitterRegular,
    Linked: LinkedRegular,
  });

  return (
    <>
      <Container4>
        <Details4>
          <Header4>Guiding professionals</Header4>
          <Body4>
            on the path of making informed career choices in just 10 minutes!!
          </Body4>
        </Details4>
        <CardWrapper1>
          <CardRow xs={2} sm={5}>
            <Col>
              <GCard
                onMouseMove={() => {
                  setValues({ ...values, product: productRegular });
                }}
                onMouseOut={() => {
                  setValues({ ...values, product: product });
                }}
              >
                <Image src={values?.product} />
              </GCard>
              <Labels>Product Design</Labels>
            </Col>
            <Col>
              <GCard
                onMouseMove={() => {
                  setValues({ ...values, structural: structuralRegular });
                }}
                onMouseOut={() => {
                  setValues({ ...values, structural: structural });
                }}
              >
                <Image src={values?.structural} />
              </GCard>
              <FirstLabels>Structural</FirstLabels>
            </Col>
            <Col>
              <GCard
                onMouseMove={() => {
                  setValues({ ...values, ev: evRegular });
                }}
                onMouseOut={() => {
                  setValues({ ...values, ev: ev });
                }}
              >
                <Image src={values?.ev} />
              </GCard>
              <Labels>EV Technology</Labels>
            </Col>
            <Col>
              <GCard>
                <Image src={auto} />
              </GCard>
              <FirstLabels>Automotive</FirstLabels>
            </Col>
            <Col>
              <GCard>
                <Image src={values?.aero} />
              </GCard>
              <Labels>Aeronautical</Labels>
            </Col>
            <Col>
              <GCard
                onMouseMove={() => {
                  setValues({ ...values, data: dataRegular });
                }}
                onMouseOut={() => {
                  setValues({ ...values, data: data });
                }}
              >
                <Image src={values?.data} />
              </GCard>
              <Labels>Data Science</Labels>
            </Col>
            <Col>
              <GCard
                onMouseMove={() => {
                  setValues({ ...values, transport: tranRegular });
                }}
                onMouseOut={() => {
                  setValues({ ...values, transport: trans });
                }}
              >
                <Image src={values?.transport} />
              </GCard>
              <Labels>Transportation</Labels>
            </Col>
            <Col>
              <GCard
                onMouseMove={() => {
                  setValues({ ...values, civil: civilRegular });
                }}
                onMouseOut={() => {
                  setValues({ ...values, civil: civil });
                }}
              >
                <Image src={values?.civil} />
              </GCard>
              <LastLabels>Civil</LastLabels>
            </Col>
            <Col>
              <GCard
                onMouseMove={() => {
                  setValues({ ...values, arch: archRegular });
                }}
                onMouseOut={() => {
                  setValues({ ...values, arch: arch });
                }}
              >
                <Image src={values?.arch} />
              </GCard>
              <Labels>Architectural</Labels>
            </Col>
            <Col>
              <GCard
                onMouseMove={() => {
                  setValues({ ...values, itcs: itcsRegular });
                }}
                onMouseOut={() => {
                  setValues({ ...values, itcs: ic });
                }}
              >
                <Image src={values?.itcs} />
              </GCard>
              <LastLabels>IT & CS</LastLabels>
            </Col>
          </CardRow>
        </CardWrapper1>
        <BackImageC4 src={BackImageC04} />
      </Container4>

      <PrivacyContainer>
        <WrapperC4>
          <LogoWrap>
            <a
              href="https://www.cloudkampus.com/"
              target="_blank"
              rel="noreferrer noopener"
              className="link"
            >
              <Logo1 src={logo2} alt="Cloud Kampus" />
            </a>
          </LogoWrap>
          <CopyRight>
          <div>Powered by Cloudkampus &copy; 2022 All rights reserved.</div>
          </CopyRight>
          {/* <BrandIcon>
            <div>Powered by Cloudkampus</div>
          </BrandIcon> */}
        </WrapperC4>
      </PrivacyContainer>
    </>
  );
};

export default Footers;
