import { useEffect, useState } from 'react'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import {
  ListInput,
  DropdownInput,
  EditDropdownWrapper,
  Title,
  FormFeedback
} from './subcomponents'
import { EditableDropdownProps } from './typings'

const EditableDropdown = ({
  isRequired,
  width,
  title,
  dropdownList,
  placeholder,
  handleSelect,
  onBlur,
  error,
  isDisabled,
  defaultValue,
  reset,
}: EditableDropdownProps) => {
  const { id, name } = defaultValue || {}
  const [selectdItem, setSelectedItem] = useState({
    id: id || '',
    name: name || ''
  })

  useEffect(() => {
    if (reset) {
      setSelectedItem({
        name: '',
        id: ''
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset])
  return (
    <EditDropdownWrapper
      width={width}
      isDisabled={isDisabled}
      id="editable-dropdown"
    >
      {title && (
        <Title>
          {`${title}`}
          {isRequired && <strong>*</strong>}
        </Title>
      )}
      <ListInput>
        <DropdownInput
          readOnly
          placeholder={placeholder}
          aria-label={placeholder}
          onBlur={() => onBlur && onBlur()}
          value={selectdItem.name}
          isValid={!error && !!selectdItem.name}
          isInvalid={!!error}
          id="input"
        />

        <DropdownButton
          variant="outline-sec"
          title=""
          id="dropdown-id"
          align="end"
          onSelect={(eventKey) => {
            const value = dropdownList[Number(eventKey)]
            setSelectedItem(value)
            handleSelect(value)
          }}
        >
          {dropdownList.map((item, index) => (
            <Dropdown.Item id="input" eventKey={index} key={`dropdown-${index}`}>
              {item.name}
            </Dropdown.Item>
          ))}
        </DropdownButton>
        <FormFeedback type="invalid">{error}</FormFeedback>
      </ListInput>
    </EditDropdownWrapper>
  )
}

export default EditableDropdown
