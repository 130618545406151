import { loginSlice } from "./reducer";
import { handleAuthenticate } from "./api";
const {
  updateHasError,
  updateIsLoggedIn,
  updatePassword,
  updateUserName,
  updateUserInfo,
  updateShowLogin,
} = loginSlice.actions;

export {
  updateHasError,
  updateIsLoggedIn,
  updatePassword,
  updateUserName,
  handleAuthenticate,
  updateUserInfo,
  updateShowLogin,
};
