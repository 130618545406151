import React, { ReactElement, useEffect, useState } from "react";
import { ActionButton, FlexWrapper, Loader } from "../../components";
import ROUTES from "../../const/routes";
import {
  PageWrapper,
  FirstWrapper,
  Titles,
  LastLogo,
  DownloadButton,
  Heading,
  ThirdWrapper,
  UListWrapper,
  ListText,
  Paras,
  Title,
  SideTitles,
  Sticky,
  CareerKompass,
  CardWrapper2,
  Recommanded,
  Paras2,
  CongratLine,
  CongratWrapper,
  StyledText,
  StyledBadge,
  WholeWrapper,
  CourseLink,
  CourseCard,
  CourseImg,
  CourseWrap,
  FinalFooter,
  Text,
  SubmitButton,
  Time,
  Buttons,
} from "./sucomponents";
import ab from "../../assests/ak4.png";
import careerKompass from "../../assests/careerKompass.svg";
import Download from "../../redux/result/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getResultbyUser } from "../../redux/TestApi/api";
import { useHistory } from "react-router-dom";
import MainHeader from "../../components/Header";
import PageBanner from "../../components/PageBanner";
import FbRegular from "../../assests/FbRegular.png";
import FbSolid from "../../assests/FbSolid.png";
import LinkedRegular from "../../assests/LinkedRegular.png";
import LinkedSolid from "../../assests/LinkedSolid.png";
import TwitterRegular from "../../assests/TwitterRegular.png";
import TwitterSolid from "../../assests/TwitterSolid.png";
import {
  FooterIcon,
  PrivacyContainer,
  BrandIcon,
  CopyRight,
  WrapperC4,
  Logo1,
} from "../LandingPage/subcomponents";
import logo2 from "../../assests/footerLogo.svg";

const ExistingUserResult = (): ReactElement => {
  const {
    Stream,
    userId,
    level,
    cat,
    tools,
    thumbnail,
    isLoading,
    newUser,
    idByUserName,
    stream_name,
    test_level,
    stream_id,
    login: { isLoggedIn },
  } = useSelector(
    (state: RootState) => ({
      Stream: state.stream.questions,
      userId: state.login.userInfo?.userId,
      level: state.stream.level.test_level,
      cat: state.Test.getResultbyuserid.cat,
      tools: state.Test.getResultbyuserid.tools,
      catmessage: state.Test.testResponseApi.cat,
      isLoading: state.Test.isLoading,
      newUser: state.login.userInfo?.newUser,
      idByUserName: state.Test.getResultbyuserid.user,
      thumbnail: state.Test.getResultbyuserid.thumbnail,
      stream_name: state.Test.getResultbyuserid.stream_name,
      test_level: state.Test.getResultbyuserid.test_level,
      stream_id: state.Test.getResultbyuserid.stream_id,
      login: state.login,
    }),
    shallowEqual
  );

  //newUser
  const [badge] = Stream.map((data) => data?.stream_name);
  const [category] = cat.map((x) => x?.name);
  const [isSpecial] = cat.map((x) => x?.is_special) || cat;
  const [des] = cat.map((x) => x.description);
  //exixtingUser
  const [firstNameById] = idByUserName.map((data) => data?.first_name);
  const [lastNameById] = idByUserName.map((data) => data?.last_name);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [grammer, setGrammer] = useState("A");
  const [valuesIcon, setValuesIcon] = useState({
    FB: FbRegular,
    Twitter: TwitterRegular,
    Linked: LinkedRegular,
  });

  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, []);

  const vowel = category?.charAt(0);
  const vowels = ["a", "e", "i", "o", "u", "A", "E", "I", "O", "U"];
  //console.log(vowel);
  useEffect(() => {
    vowels.forEach((element) => {
      //console.log(element);
      if (vowel == element) {
        setGrammer("AN");
      }
    });
    //console.log(grammer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vowel]);
  
  const [des_1, setDess] = useState("");
  //console.log(category);
  useEffect(() => {
    //setDess("Given below are some of the software tools that will help you establish your presence as a product analyst:");
    if(category?.includes('Project Head')){
      setDess("");
    }else{
      setDess("Given below are some of the software tools that will help you establish your presence as a "+ category +":");
    }
    // eslint-disable-next-lin                                                                e react-hooks/exhaustive-deps
  }, [category]); 

  useEffect(() => {
    if (!isLoggedIn) {
      history.push(ROUTES.LOGIN);
    }
  }, [isLoggedIn]);

  const programsText = thumbnail.length === 1 ? "program" : "programs";

  useEffect(() => {
    dispatch(
      getResultbyUser({
        id: userId,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newUser]);
  //console.log(thumbnail);
  //  useEffect(() => {
  //    if (thumbnail) {
  //      history.push(ROUTES.LOGIN);
  //    }
  //  }, [thumbnail]);
  
  const content = (
    <>
      <p>{des}</p>
      <p>{des_1}</p>
      <UListWrapper>
          {tools.map((x) => (
            <ListText>{x.tool}</ListText>
          ))}
      </UListWrapper>
    </>
  );

  return (
    <PageWrapper>
      <MainHeader />
      <WholeWrapper>
        <PageBanner
          heading="Your brightest path is out there. Get started!!"
          testName={stream_name}
          id={stream_id}
          level={test_level}
        />
        <div className="main-wrapper">
          <FirstWrapper>
            <CongratWrapper>
              {isLoading ? (
                <Loader />
              ) : (
                <CongratLine>
                  {isSpecial === 0 ? (
                    `Congratulations.. ${firstNameById} ${lastNameById}`
                  ) : (
                    <div className="Title2">
                      {" "}
                      'Responses Invalid for Personalised Recommendations'
                    </div>
                  )}
                </CongratLine>
              )}
            </CongratWrapper>
            <Title>{isSpecial === 0 ? category : ""}</Title>
            {isSpecial === 0 ? (
              <div className="content-wrapper">
                <div className="content">{content}</div>
                <div className="badge-content">
                  <CareerKompass src={careerKompass} />
                  <StyledText>YOU ARE {grammer}</StyledText>
                  <StyledBadge>{category}</StyledBadge>
                </div>
              </div>
            ) : (
              <div className="content-wrapper">
                <div className="content">
                <p>{des}</p>
                <p>{des_1}</p>
                <UListWrapper>
                    {tools.map((x) => (
                      <ListText>{x.tool}</ListText>
                    ))}
                  </UListWrapper>
                </div>
              </div>
            )}
            {/* <div className="content-wrapper mt-24">
              <CardWrapper2>
                <Paras>{des}</Paras>
                <FlexWrapper>
                  <UListWrapper>
                    {tools.map((x) => (
                      <ListText>{x.tool}</ListText>
                    ))}
                  </UListWrapper>
                </FlexWrapper>
              </CardWrapper2>
            </div> */}
            <div className="content-wrapper mt-24">
              <div>
                <SideTitles>Certified programs :</SideTitles>
                <Paras2>
                  Cloudkampus offers Certified programs that equip you with the
                  recommended software and technical acumen required for
                  excelling as {category}. This program also assures you
                  internship/placement opportunities
                </Paras2>
                <Recommanded>
                  Following are the recommended Cloudkampus Certified{" "}
                  {programsText} for you:
                  <CourseWrap>
                    {thumbnail.map((item, index) => (
                      <CourseCard width="auto" key={index}>
                        <CourseImg src={item?.image} />
                        <CourseLink href={item?.link+"?ad=46&cm=1272&adpage=Careerkompass"} target="_blank">
                          {item?.name}
                        </CourseLink>
                        <ActionButton
                          style={{
                            background: "#3335CF",
                            fontSize: "10px",
                            marginRight: "0",
                          }}
                          href={item?.link+"?ad=46&cm=1272&adpage=Careerkompass" }
                          target="_blank"
                        >
                          View Program{" "}
                          <FontAwesomeIcon icon={["fas", "chevron-right"]} />
                        </ActionButton>
                      </CourseCard>
                    ))}
                  </CourseWrap>
                </Recommanded>

                <SideTitles>Subscription plan :</SideTitles>
                <Paras2>
                  You can choose a subscription plan suitable for you and enroll
                  in a number of individual courses and learn all the
                  recommended software / skills - without having to pay for each
                  and every course separately. Subscription plans are the best
                  when you want to learn more than one course.
                </Paras2>
                <SideTitles>Individual programs :</SideTitles>
                <Paras2>
                  Cloudkampus offers niche, standalone courses in the recommended software. You can always take up these courses.
                </Paras2>
              </div>
            </div>
            <FinalFooter>
              <Text>Manage your career planning better, speak to our Career Advisor today!</Text>
              {/* <SubmitButton><a style={{ textDecoration: "none", color: "white" }} href="mailto:info@cloud">Email us</a></SubmitButton> */}
              <Buttons><a style={{ textDecoration: "none", color: "white" }} href="tel:+917824020024">+91 78240 20024</a></Buttons>
              <Time>10 am - 7 pm IST</Time>
            </FinalFooter>
          </FirstWrapper>
          <ThirdWrapper>
            <Sticky>
              <FlexWrapper justifyContent="center">
                <Titles>Your Result for</Titles>
                <Heading>
                  {stream_name} - {test_level}
                </Heading>
                <LastLogo src={ab} />
              </FlexWrapper>
              <FlexWrapper justifyContent="center" marginTop={-5.8}>
                <DownloadButton
                  onClick={() => {
                    Download(userId);
                  }}
                >
                  <FontAwesomeIcon icon={["fas", "download"]} size="sm" />
                  <span style={{ marginLeft: "3%" }}>Download</span>
                </DownloadButton>
              </FlexWrapper>
            </Sticky>
          </ThirdWrapper>
        </div>
      </WholeWrapper>
      <PrivacyContainer>
        <WrapperC4>
          <div>
            <a
              href="https://www.cloudkampus.com/"
              target="_blank"
              rel="noreferrer noopener"
              className="link"
            >
              <Logo1 src={logo2} alt="Cloud Kampus" />
            </a>
          </div>
          {/* <BrandIcon>
            <a
              href="https://www.facebook.com/Cloudkampus/"
              target="_blank"
              rel="noreferrer noopener"
              className="link"
            >
            <FooterIcon
                src={valuesIcon.FB}
                onMouseOver={() => {
                  setValuesIcon({ ...valuesIcon, FB: FbSolid });
                }}
                onMouseOut={() => {
                  setValuesIcon({ ...valuesIcon, FB: FbRegular });
                }}
              />
            </a>
            <a
              href="https://twitter.com/cloudkampus1"
              target="_blank"
              rel="noreferrer noopener"
              className="link"
            >
              <FooterIcon
                src={valuesIcon.Twitter}
                onMouseOver={() => {
                  setValuesIcon({ ...valuesIcon, Twitter: TwitterSolid });
                }}
                onMouseOut={() => {
                  setValuesIcon({ ...valuesIcon, Twitter: TwitterRegular });
                }}
              />
            </a>
            <a
              href="https://www.linkedin.com/company/cloudkampus"
              target="_blank"
              rel="noreferrer noopener"
              className="link"
            >
              <FooterIcon
                src={valuesIcon.Linked}
                onMouseOver={() => {
                  setValuesIcon({ ...valuesIcon, Linked: LinkedSolid });
                }}
                onMouseOut={() => {
                  setValuesIcon({ ...valuesIcon, Linked: LinkedRegular });
                }}
              />
            </a>
          </BrandIcon> */}
          <CopyRight>
          <div>&copy; 2022 CloudKampus. All rights reserved.</div>
          </CopyRight>
          <BrandIcon>
            <div>Powered by Cloudkampus</div>
          </BrandIcon>

        </WrapperC4>
      </PrivacyContainer>
    </PageWrapper>
  );
};

export default ExistingUserResult;
