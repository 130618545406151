import { Button, Dropdown } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "../../const/theme";

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  z-index: 1000;
  background-color: #ffffff;
  box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.1);
  padding: 16px;
  @media screen and (min-width: 650px) {
    padding: 16px 10%;
  }
`;

export const Logo = styled.img`
  height: 54px;
  display: flex;
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 12px;
`;

export const LoginButton = styled(Button)`
  letter-spacing: 0px;
  color: rgba(255, 123, 0, 1);
  text-transform: capitalize;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  padding: 5px 23px;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0px 0px 8px #888888;
  border-color: blue;
  justify-content: flex-end;
`;

export const MenuContainer = styled.div`
  color: #0f1043;
  font-family: "Montserrat", sans-serif;
  text-transform: capitalize;
  opacity: 1;
  font-size: 12px;
  cursor: pointer;
  font-weight: 700;
`;

export const HamburgerMenu = styled(Button)`
  background: none;
  border: none;
  :hover {
    background: white;
    color: #3335cf;
  }
  font-size: 17px;
  color: #3335cf;
`;

export const MenuList = styled.span`
  margin: 12px;
  padding-bottom: 2px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  color: #0f1043;
  cursor: pointer;
  :hover {
    color: #ff7b00; 
  }
  @media (max-width: 650px) {
    font-size: 10px;
    padding: 2px;
  }
  @media (max-width: 450px) {
    font-size: 10px;
    padding: 2px;
  }

`;

export const MenuListWrapper = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;
export const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ProfileContainer = styled(Dropdown)`
  border: 2px solid #3335cf;
  border-radius: 20px;
  min-width: 150px;
  height: 40px;
  z-index: 100;
  background: none;
  &:focus,
  &:active {
    background: none;
  }
  #profile-dropdown {
    width: 100%;
    color: ${colors.white};
    border: none;
    border-radius: 10px;
    display: flex;
    background: none;
    &:focus,
    &:hover,
    &:active {
      background: none;
      outline: none;
      border: none;
    }
  }
  .link {
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    font-weight: 500;
  }
  @media (max-width: 450px) {
    z-index: 1;
    min-width: 0;
    width: 100%;
    height: 40px;
  }
`;
export const ProfileImage = styled.img`
  height: 31px;
  min-width: 31px;
  left: -10px;
  position: relative;
  padding: 0%;
  bottom: 3px;
  overflow: hidden;
  border-radius: 50%;
`;
export const UserName = styled.label`
  color: #ff7b00;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
  margin: auto 0px;
  cursor: pointer;
`;

export const ProfileDropDown = styled(Dropdown)`
  margin: auto;
  margin-right: 0;
  #profile-dropdown {
    color: ${colors.white};
    border: none;
    display: flex;
    background: none;
  }
`;

export const Errorwrapper = styled.div` 
font-size: 11px;
position: relative;
color: red;
top: -16px;

`

