import { ReactElement, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  FlexWrapper,
  Loader,
  PageWrapper,
  ReactDropdown,
} from "../../components";
import {
  Body,
  BodyBackImage,
  CircleImagec,
  ContainerWrapper,
  DropdownWrapper,
  Header1,
  Header2,
  Gearup,
  LevelCard,
  Para,
  SelectLevelWrapper,
  StreamWrapper,
  StreamSelectionWrapper,
  TestButton,
} from "./subcomponent";
import { getLevelsDropdown, getStreamDropdown } from "../../helpers/dropdown";
import { RootState } from "../../redux/store";
import {
  GetQuestionByTest,
  handleStreamMaster,
  SelectedStream,
} from "../../redux/streamMaster/api";
import { useHistory } from "react-router-dom";
import ROUTES from "../../const/routes";
import { DropdownListProps } from "../../components/Dropdown/typings";
import {
  updateSelectedLevel,
  updateSelectedStream,
} from "../../redux/streamMaster/action";
import circleImage from "../../assests/selectStreamBackImg.svg";
import MainHeader from "../../components/Header";

const StreamSelection = (): ReactElement => {
  const {
    streamList,
    levelData,
    SelectStream,
    TestId,
    isLoading,
    login: { isLoggedIn },
    newUser,
  } = useSelector(
    (state: RootState) => ({
      streamList: state.stream.streamMaster,
      levelData: state.stream.getTest,
      SelectStream: state.stream.selectStream,
      TestId: state.stream.testID,
      isLoading: state.stream.isLoading,
      login: state.login,
      newUser: state.login.userInfo?.newUser,
    }),
    shallowEqual
  );
  const StreamDropdown = streamList ? getStreamDropdown(streamList) : [];
  const LevelDropdown = levelData ? getLevelsDropdown(levelData) : [];

  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(handleStreamMaster());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [stream, setStream] = useState(SelectStream);
  const [TestID, setTestID] = useState(TestId);
  const [disable, setdisable] = useState(true);
  const [isActive, setisActive] = useState(false);
  const [isActivePH, setisActivePH] = useState(false);
  const [show, setShow] = useState(false);

  const [colors, setColors] = useState({ h: false, a: false })

  useEffect(() => {
    if (history.location.pathname === ROUTES.LOGIN) {
      setColors({
        ...colors,
        h: true
      })
    } else if (history.location.pathname === ROUTES.ABOUT) {
      setColors({
        ...colors,
        a: true
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    dispatch(SelectedStream(stream));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stream]);

  useEffect(() => {
    if (isActive === true) {
      setisActivePH(false);
    }
  }, [isActive]);

  useEffect(() => {
    if (isActivePH === true) {
      setisActive(false);
    }
  }, [isActivePH]);

  useEffect(() => {
    if (!isLoggedIn) {
      history.push(ROUTES.LOGIN);
    }
    if (isLoggedIn) {
      if (newUser){
        history.push(ROUTES.STREAMSELECTION);
      }
      else {
        history.push(ROUTES.EXISTING_USER); 
      }
    }
  }, [isLoggedIn]);

  const options = [
    {name: 'Swedish', value: 'sv'},
    {name: 'English', value: 'en'},
  ];

  return (
    <ContainerWrapper>
      <MainHeader />
      <Body>
        <BodyBackImage></BodyBackImage>
        <Header1>Are you all set?</Header1>
        <FlexWrapper justifyContent="center">
          <Para>
            Unlock new quests and know more about your uniqueness by answering
            Careerkompass's alternative-response questions. This quick
            assessment measures your traits, interests, and skills in key areas.
            Follow your heart and choose the answers, your choices pave the way
            towards your ideal career based on your{" "}
            <p> job role with our personalized guide.</p>
          </Para>
        </FlexWrapper>
        <Header2 fontSize="25px">Select your desired Stream and choose level</Header2>
        <StreamSelectionWrapper>
          <StreamWrapper>
            {/* <DropdownWrapper>
              <EditableDropdown
                dropdownList={StreamDropdown}
                placeholder={"Select Stream"}
                handleSelect={(value: DropdownListProps) => {
                  setStream({ streamID: value?.id });
                  setShow(true);
                  setisActive(false)
                  setisActivePH(false)
                  dispatch(updateSelectedStream({
                    streamID: value?.id
                  }))
                }}
              />
            </DropdownWrapper> */}
            <DropdownWrapper>
              <ReactDropdown
                dropdownList={StreamDropdown}
                singleSelect
                placeholder={"Select  Stream"}
                handleSelect={(value) => {
                  const ids = value.map((item: DropdownListProps) => item?.id);
                  setStream({ streamID: `${ids}` });
                  setShow(true);
                  setisActive(false);
                  setisActivePH(false);
                  dispatch(
                    updateSelectedStream({
                      streamID: `${ids}`,
                    })
                  );
                }}
              />
            </DropdownWrapper>
          </StreamWrapper>
          <PageWrapper height={103}>
          {show ? (
            <>
              <SelectLevelWrapper>
                <Header2 fontSize="11px">
                  Select the career roadmap according to your level of experience
                </Header2>
              </SelectLevelWrapper>
              {isLoading ? (
                <Loader />
              ) : (
                <FlexWrapper justifyContent="space-between" noPadding>
                  {LevelDropdown.map((x) => {
                    if (x.name === "Expert") {
                      return (
                        <LevelCard
                          responsive={true}
                          className="btn btn"
                          onClick={() => {
                            setTestID({ testID: x.id });
                            dispatch(updateSelectedLevel({ test_level: x.name }));
                            setdisable(false);
                            setisActive(!isActive);
                          }}
                          active={isActive}
                        >
                          {x.name}
                          <br />
                          <span style={{ fontSize: "12px" }}>
                            (1-3 years experience)
                          </span>
                        </LevelCard>
                      );
                    } else {
                      return (
                        <LevelCard
                          responsive={true}
                          onClick={() => {
                            setTestID({ testID: x.id });
                            dispatch(updateSelectedLevel({ test_level: x.name }));
                            setdisable(false);
                            setisActivePH(!isActivePH);
                          }}
                          active={isActivePH}
                        >
                          {x.name}
                          <br />
                          <span style={{ fontSize: "12px" }}>
                            (3+ years experience)
                          </span>
                        </LevelCard>
                      );
                    }
                  })}
                </FlexWrapper>
              )}
            </>
          ) : null}
          </PageWrapper>
        </StreamSelectionWrapper>
      </Body>
      <FlexWrapper justifyContent="center" height={80}>
        <TestButton
          variant="secondary"
          disabled={disable}
          onClick={() => {
            dispatch(GetQuestionByTest({ testID: TestID.testID }));
          }}
        >
          Take Assessment
        </TestButton>
        <CircleImagec src={circleImage} />
      </FlexWrapper>
      <Gearup>
        Gear up! As the assessment can be accessed only once.
      </Gearup>

    </ContainerWrapper>
  );
};

export default StreamSelection;
