import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { postAnswer, getResultbyUser } from "./api";
import { InitialState, TestResponce } from "./types";

const initialState: InitialState = {
  isLoading: false,
  TestPayload: {
    testID: "",
    userID: "",
    answer: [],
  },
  AnswerList: [],
  TestResponce: {
    user: [],
    cat: [],
    tools: [],
    course: [],
    grap: '',
    thumbnail: [],
    stream_name: '',
    test_level: '',
    stream_id: 0,
  },
  testResponseApi: {
    cat: ''
  },
  getResultbyuserid: {
    user: [],
    cat: [],
    tools: [],
    course: [],
    grap: '',
    stream: '',
    thumbnail: [],
    stream_name: '',
    test_level: '',
    stream_id: 0,
  },
};
export const TestSlice = createSlice({
  name: "Test",
  initialState,
  reducers: {
    updateHasError: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    }, 
  },
  extraReducers: {
    [postAnswer.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [postAnswer.fulfilled.toString()]: (
      state,
      action: PayloadAction<TestResponce>
    ) => {
      state.isLoading = false;
      state.TestResponce = action?.payload;
    },
    [postAnswer.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    // [postAnswer.fulfilled.toString()]: (
    //   state,
    //   action: PayloadAction<TestResponse>
    // ) => {
    //   state.isLoading = false;
    //   state.testResponseApi = action?.payload;
    // },
    [getResultbyUser.fulfilled.toString()]: (
      state,
      action: PayloadAction<TestResponce>
    ) => {
      state.isLoading = false;
      state.getResultbyuserid = action?.payload;
    },
  },
});

export default TestSlice.reducer;
