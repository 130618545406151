import HighchartsReact from "highcharts-react-official";
import { Button, Card, Figure } from "react-bootstrap";
import styled from "styled-components";
import badgeImg from "../../assests/badge.svg";

export const Logs = styled.img`
  width: 98%;
  position: absolute;
  height: 100px;
  display: flex;
`;

export const PageWrapper = styled.div`
  width: 100%;
`;
export const Time = styled.div`
  width: 17%;
  padding-top:18px;
  color:#fff;
  @media (max-width: 450px) {
    font-size: 12px;
    padding-top:5px;
    width: 50%;
  } 
`;
export const Text = styled.div`
  margin-top: 2;
  font-family: "Montserrat", sans-serif;
  color: white;
  font-size: 18px;
  width: 49%;
  font-weight: 600;
  @media (max-width: 600px) {
    font-size: 10px;
    width: 100%;
    margin: 3% auto;
  }
  @media (max-width: 1200px) {
    font-size: 15px;
    width: 100%;
    margin: auto 0;
  }
  @media (max-width: 450px) {
    font-size: 15px;
  }
`;

export const CopyRight = styled.div`
  display: flex;
  justify-content: center;
  background: none;
  font-size: 15px;
  border: none;
  color: #000;
  gap: 8px;
  .link {
    width: 32px;
  }
  @media (max-width: 750px) {
    margin: 0;
  }
  @media (max-width: 450px) {
    margin: 10px auto;
  }
`;

export const BrandIcon = styled.div`
  display: flex;
  justify-content: center;
  background: none;
  font-size: 15px;
  border: none;
  color: #000;
  gap: 8px;
  .link {
    width: 32px;
  }
  @media (max-width: 750px) {
    margin: 0;
  }
  @media (max-width: 450px) {
    margin: 0;
  }
`;

export const FinalFooter = styled.div`
  display: flex;
  justify-content: space-around;
  background-color: rgb(68, 70, 212);
  width: 90%;
  margin-left: 10%;
  padding: 3%;
  border-radius: 17px;
  margin-top: 4%;
  @media (max-width: 600px) {
    margin: 5% auto;
    width: 100%;
  }
  @media (max-width: 450px) {
    width: 91%;
    margin-left: 1%;
    padding: 3%;
    height: auto;
    display: block;
  }
`;

interface ParaProps {
  marginTop?: string;
  color?: string;
  marginLeft?: string;
  width?: string;
}

export const Para = styled.p<ParaProps>`
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  line-height: 1.6;
  width: ${({ width }) => (width ? `${width}%` : "50%")};
  font-size: 13px;
  margin: 0 auto;
  color: ${({ color }) => (color ? color : "#000000")};
  @media (max-width: 600px) {
    margin: 0 auto;
    font-size: 9px;
    width: 100%;
  }
  @media (max-width: 1200px) {
    margin: 0 auto;
    font-size: 14px;
    font-weight: 550;
    width: 100%;
  }
`;
export const Paras = styled.p<ParaProps>`
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: ${({ color }) => (color ? color : "black")};
  
`;

export const UListWrapper = styled.ul`
  line-height: 2;
  margin-top:20px;
  @media (max-width: 600px) {
  }
`;

export const ListText = styled.li`
  font-style: Bold;
  font-size: 14px;
  font-weight: 550;
  @media (max-width: 500px) {
    font-size: 12px;
  }
  @media (max-width: 900px) {
    font-size: 12px;
  }
`;

export const ContentPageLogo = styled(Figure.Image)`
  width: 68%;
  top: -17px;
  position: relative;
  @media (max-width: 500px) {
  }
  @media (max-width: 900px) {
  }
`;

export const ContentLogo = styled(Figure.Image)`
  width: 40%;
  margin-left: 20%;
  @media (max-width: 600px) {
    margin: 0 auto;
    width: 100%;
  }
`;

export const CardLogo = styled(Figure.Image)`
  width: 98%;
`;

export const LastLogo = styled(Figure.Image)`
  width: 35%;
`;

export const SideTitles = styled.p`
  font-weight: 650;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  margin-top: 5px;
  color: #3335cf;
  width: 100%;
  color: ${({ color }) => (color ? color : null)};
  @media (max-width: 600px) {
    float: right;
    margin: 0 auto;
    padding-bottom: 2%;
  }
`;

export const Titles = styled.p`
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  text-transform:uppercase;
  font-weight: 600;
  color: rgb(51, 53, 207);
  text-align: center;
  opacity: 1;
  width: 100%;
  color: ${({ color }) => (color ? color : null)};
  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

export const Heading = styled.p`
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #43566d;
  align-items: center;
  text-align: center;
  opacity: 1;
  width: 100%;
  color: ${({ color }) => (color ? color : null)};
  @media (max-width: 600px) {
    font-size: 55%;
  }
`;

export const SubmitButton = styled(Button)`
  height: 35px;
  font-family: "Montserrat", sans-serif;
  width: 100px;
  background: #ff7b00 0% 0% no-repeat padding-box;
  border: none;
  font-size: 13px;
  color: #ffffff;
  opacity: 1;
  margin-left: 20%;
  border-radius: 17px;
  text-decoration:none;
  &:hover,
  &:active,
  &:focus {
    background: #3335cf 0% 0% no-repeat padding-box;
  }
  @media (max-width: 600px) {
    font-size: 4px;
    height: fit-content;
    margin: 2% auto;
  }
  @media (max-width: 1200px) {
    font-size: 12px;
    width: 50%;
    margin: auto 0;
    margin-right: 10px;
  }
  @media (max-width: 450px) {
    font-size: 7px;
  }
`;
export const ViewButton = styled(Button)`
  width: 140px;
  background: #3335cf 0% 0% no-repeat padding-box;
  font-size: 12px;
  color: #ffffff;
  justify-content: space-around;
  opacity: 1;
  margin-left: 35%;
  &:hover,
  &:active,
  &:focus {
    background: #ff7b00 0% 0% no-repeat padding-box;
  }
  @media (max-width: 600px) {
    font-size: 9px;
    width: 60%;
  }
`;

export const DownloadButton = styled(Button)`
  height: 35px;
  width: 120px;
  background: #ff7b00 0% 0% no-repeat padding-box;
  border: none;
  font-size: 14px;
  color: #ffffff;
  opacity: 1;
  margin-top: 2%;
  &:hover,
  &:active,
  &:focus {
    background: #ff7b00 0% 0% no-repeat padding-box;
  }
  &:disabled {
    background: #ff7b00 0% 0% no-repeat padding-box;
  }
  @media (max-width: 600px) {
    font-size: 68%;
    width: 75%;
  }
  @media (max-width: 450px) {
    width: 120px;
    font-size: 14px;
  }
  span {
    font-family: "Montserrat", sans-serif;
  }
`;

export const Buttons = styled(Button)`
  height: 35px;
  width: 180px;
  font-weight:600;
  margin-top:10px;
  margin-left:5px;
  background: #ff7b00 0% 0% no-repeat padding-box;
  border: none;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  border-radius: 17px;
  opacity: 1;
  &:hover,
  &:active,
  &:focus {
    background: #3335cf 0% 0% no-repeat padding-box;
  }
  @media (max-width: 600px) {
    font-size: 4px;
    height: fit-content;
    margin: 2% auto;
  }
  @media (max-width: 1200px) {
    font-size: 12px;
    width: 50%;
    margin: 3% auto;
  }
  @media (max-width: 450px) {
    font-size: 12px;
  }
`;

export const FirstWrapper = styled.div`
  background-color: #fcfcff;
  width: 100%;
  @media (max-width: 450px) {
    width: 100%;
  }
  padding-bottom: 5%;
  .content-wrapper {
    &.mt-24 {
      margin-top: 24px;
    }
    display:flex;
    flex-direction: row;
    padding: 0 5%;
    gap: 10px;
    align-items: center;
    font-family: Montserrat;
    font-size: 14px;

    @media (max-width: 450px) {
      flex-direction: column;
    }
    .content {
      width: 65%;
      text-align:justify;
      margin-right:15px;
      @media (max-width: 450px) {
        width: 100%;
      }
    }
    .badge-content {
      display: flex;
      width: 35%;
      margin-top:-30px;
      background-image: url(${badgeImg});
      background-size: 90%;
      background-repeat: no-repeat;
      height: 296px;
      align-self:flex-start;
      background-position: center;
      padding: 7% 7%;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      @media (max-width: 450px) {
        width: 100%;
        padding: 27% 27%;
        background-size: 70%;
      }
    }
  }
`;

export const ThirdWrapper = styled.div`
  background-color: #f6f6fe;
  width: 35%;
  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const Label = styled.div`
  color: #ff7b00;
  text-align: center;
  font-family: "cursive";
  font-size: 38px;
  padding-top: 2.5%;
  background: var(--unnamed-color-ff7b00) 0% 0% no-repeat padding-box;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

export const Title = styled.div`
  color: #3335cf;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 900;
  padding: 16px 5% 8px 5%;
  @media (max-width: 600px) {
    font-size: 15px;
    margin: 0 auto;
  }
  @media (max-width: 1200px) {
    font-size: 18px;
    margin: 0 auto;
  }
`;

export const LastTitles = styled.div`
  color: #000000;
  font-family: normal normal bold 40px/60px Montserrat;
  font-size: 19px;
  font-weight: 500;
  margin-left: 2%;
  margin-top: 2%;
`;

interface bold {
  fontSize?: string;
  fontWeight?: string;
}
export const Bold = styled.p<bold>`
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "700")};
  color: #3335cf;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "25px")};
  font-family: "Montserrat", sans-serif;
  color: #3335cf;
  margin: auto 0;
  width: auto;
  @media (max-width: 1000px) {
    font-size: 14px;
    margin: auto 0;
    padding-left: 4%;
  }
  @media (max-width: 650px) {
    font-size: 14px;
    padding-left: 4%;
    margin: auto 0;
  }
  @media (max-width: 450px) {
    font-size: 11px;
    padding-right: 10px;
    margin: auto 0;
  }
`;

export const WholeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 86px;
  .main-wrapper {
    display: flex;
    flex-direction: row;
    @media (max-width: 450px) {
      flex-direction: column;
    }
  }
`;

export const FlexWrap = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  background-color: #fff2e1;
  height: 110px;
  #logoWrapper {
    display: flex;
    @media (max-width: 1000px) {
    }
    @media (max-width: 650px) {
      width: auto;
      display: flex;
      margin: auto;
    }
    @media (max-width: 450px) {
      width: auto;
      display: flex;
      margin: auto;
    }
  }
  @media (max-width: 1000px) {
    width: 100%;
  }
  @media (max-width: 650px) {
    width: 100%;
  }
  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const Sticky = styled.div`
  height: 200px;
  position: fixed;
  @media (max-width: 450px) {
    height: auto;
    position: relative;
  }
`;

export const RelativeWrap = styled.div`
  position: relative;
  z-index: 1000;
`;

export const CareerKompass = styled(Figure.Image)`
  width: 55%;
`;

export const CardWrapper2 = styled(Card)`
  width: 100%;
  padding: 16px;
  display: flex;
  border-radius: 8px;
  box-shadow: 0px 1px 2px lightgray;
`;

export const Recommanded = styled.div`
  color: black;
  font-size: 15px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  padding: 10px 0px 10px 0px;
  #courseName {
    display: flex;
    justify-content: center;
    font-family: "Montserrat", sans-serif;
    color: #ff7b00;
    margin: 15px;
  }
  @media (max-width: 500px) {
    width: 100%;
    font-size: 14px;
  }
  @media (max-width: 900px) {
    width: 100%;
    font-size: 14px;
  }
  @media (max-width: 450px) {
    font-size: 14px;
  }
`;

export const Paras2 = styled.p<ParaProps>`
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 1px 0px 10px 0px;
  margin: auto 0;
  color: ${({ color }) => (color ? color : "black")};
  @media (max-width: 600px) {
    margin: 0 auto;
    font-size: 12px;
    width: 100%;
  }
  @media (max-width: 900px) {
    margin: 0 auto;
    font-size: 13px;
    width: 100%;
  }
  @media (max-width: 450px) {
    font-size: 14px;
  }
`;

export const CongratWrapper = styled.div`
  width: 100%;
  margin: auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid lightgray;
  padding: 32px 16px;
`;
export const CongratLine = styled.div`
  font-size: 44px;
  color: #ff7b00;
  font-family: "BeneScriptine";
  font-weight: 500;
  text-align: center;
  @media (max-width: 1000px) {
    font-size: 32px;
  }

  .Title2 {
    text-align: center;
    font-size: 40px;
    color: #ff7b00;
    font-family: "BeneScriptine";
    font-weight: 500;
    @media (max-width: 1000px) {
      font-size: 32px;
    }
  }
`;

export const BadgeText = styled.p`
  font-size: 15px;
  font-family: "Montserrat";
  font-weight: 500;
  @media (max-width: 600px) {
    font-size: 12px;
    margin-top: -26%;
  }
`;

export const StyledText = styled.p`
  font-size: 15px;
  font-family: "Montserrat";
  font-weight: 500;
  margin-bottom:0px;
  @media (max-width: 600px) {
    font-size: 12px;
    margin-top: -26%;
  }
`;


export const StyledBadge = styled.div`
  font-family: "Montserrat";
  color: rgb(51, 53, 207);
  width: 82%;
  text-align: center;
  font-weight: 600;
  font-size: 13px;

  @media (max-width: 1100px) {
    font-size: 12px;
  }
`;

export const CourseLink = styled.a`
  font-size: 15px;
  padding-top: 5px;
  text-decoration: none;
  color: #f37a20;
  &:hover {
    color: #f37a20;
  }
`;

export const HighChartUI = styled(HighchartsReact)`
  font-family: "Montserrat", sans-serif;
`;

export const Chart = styled.div`
  margin: 0;
  @media (max-width: 1000px) {
    width: 100px;
  }
`;

export const CourseWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: start;
  gap: 15px;
`;

export const CourseCard = styled(Card)`
  border: 1px solid #6264c5;
  padding: 12px 10px 16px;
  margin: 12px 0;
  width: 342px;
  @media (max-width: 450px) {
    width: auto;
    height: auto;
  }
`;

export const CourseImg = styled.img`
  width: 320px;
  height: 240px;
  border-radius: 5px;
  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const BadgeinnerText = styled.text`
  font-size: 13px;
  position: relative;
  top: -10px;
`;
