import { createAsyncThunk } from "@reduxjs/toolkit";
import apiEndpoints from "../../const/apiEndpoints";
import api from "../../services";
import { UserInfo } from "./types";
 
export const handleAuthenticate = createAsyncThunk(
  "user/login",
  async (data: any): Promise<UserInfo> => {
    const response = await api.post(apiEndpoints.loginend, data);
    const userInfo = response?.data;
    sessionStorage.setItem("Authorization", userInfo?.token);
    sessionStorage.setItem("userId", userInfo?.userId);
    sessionStorage.setItem("user_name", userInfo?.user_name);
    sessionStorage.setItem("last_name", userInfo?.last_name);
    // localStorage.setItem("name", data.email);
    // localStorage.setItem("password", data.password);
    return userInfo;
  }
);
