import styled from "styled-components";
import { Button } from "react-bootstrap";

const ActionButton = styled(Button)`
  margin: ${({ margin }) => (margin ? `${margin}%` : "auto")};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : "10px")};
  margin-left: ${({ marginLeft }) => (marginLeft ? `${marginLeft}px` : "auto")};
  margin-right: ${({ marginRight }) =>
    marginRight ? `${marginRight}px` : "auto"};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? `${backgroundColor}` : "#f47f34"};
  border: none;
  font-family: "Montserrat", sans-serif;
  &:hover,
  &:focus {
    background-color: #f47f34;
  }
  &:active {
    background-color: #f47f34;
  }
  #logoWrapper {
    font-family: "Montserrat", sans-serif;
  }
  @media (max-width: 1000px) { 
  }
  @media (max-width: 650px) { 
  }
  @media (max-width: 450px) { 
    font-size: 10px;
  }
`;

export default ActionButton;
