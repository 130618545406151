import { Button, Card, Figure, Row } from "react-bootstrap";
import styled, { css } from "styled-components";
import Backimage1 from "../../assests/container3.svg";
import HomPageDEcoration from "../../assests/HomPageDEcoration.png";

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
  @media (max-width: 415px) {
    width: 98%;
    overflow: auto;
  }
`;

export const CompassWrapper = styled.div`
  position: relative;
  min-height: 24rem;
  display: flex;
  align-items: center;

  @media screen and (min-width: 650px) {
    min-height: 47rem;
  }
`;

/*-------------------------------------container1------------------------------------------*/

export const Container1 = styled.div`
  width: 100%;
  min-height: 470px;
  // background: url(${HomPageDEcoration});
  background: linear-gradient(281.87deg, #0F106A -2.41%, #3335CF 107.28%);
  background-position: center;
  background-size: cover;
  z-index: 200;
  margin-top: 86px;
  @media (max-width: 1200px) {
    height: 520px;
  }
  @media (max-width: 1000px) {
    height: 500px;
  }
  @media (max-width: 850px) {
    height: 430px;
  }
  @media (max-width: 650px) {
    height: 340px;
  }
  @media (max-width: 450px) {
    height: 250px;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #ffffff;
  box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  @media (max-width: 600px) {
    margin: 10px 0px 10px -10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  margin: auto 60px;
  @media (max-width: 500px) {
    margin: auto 6px;
  }
`;
export const Logo = styled.img`
  margin: 0 auto;
  width: 90px;
  height: 35%;
  display: flex;
  margin: auto 12px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: 85px;
  @media (max-width: 500px) {
    margin-right: 0px;
  }
`;

export const LoginButton = styled(Button)`
  letter-spacing: 0px;
  color: rgba(255, 123, 0, 1);
  text-transform: capitalize;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  padding: 5px 23px 5px 23px;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0px 0px 8px #888888;
  border-color: blue;
  justify-content: flex-end;
  margin: 12px 15px 12px 12px;
`;

export const MenuContainer = styled.div`
  color: #0f1043;
  font-family: "Montserrat", sans-serif;
  text-transform: capitalize;
  opacity: 1;
  font-size: 12px;
  cursor: pointer;
  margin: 16px 2px;
  font-weight: 700;
`;

export const HamburgerMenu = styled(Button)`
  background: none;
  border: none;
  :hover {
    background: white;
    color: #3335cf;
  }
  font-size: 17px;
  color: #3335cf;
`;

export const Details = styled.div`
  margin: 7% 10% 7% 10%;
  @media (max-width: 450px) {
    margin: 12% 10% 7% 10%;
  }  
`;

export const Header = styled.header`
  font-size: 45px;
  font-weight: 700;
  line-height:55px;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  @media (max-width: 1000px) {
    font-size: 30px;
  }
  @media (max-width: 850px) {
    font-size: 24px;
  }
  @media (max-width: 650px) {
    font-size: 25px;
    line-height:25px;
  }
  @media (max-width: 450px) {
    font-size: 24px;
  }
`;

export const Body = styled.body`
  color: #fff;
  font-size: 30px;
  background: none;
  margin-top: 10px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  @media (max-width: 1000px) {
    font-size: 20px;
  }
  @media (max-width: 650px) {
    font-size: 16px;
  }
  @media (max-width: 450px) {
    font-size: 17px;
  }
`;

export const Footer = styled.footer`
  font-size: 27px;
  width: 50%;
  color:#fff;
  margin-top: 20px;
  line-height:28px;
  margin-bottom:10px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  @media (max-width: 1000px) {
    font-size: 12px;
  }
  @media (max-width: 650px) {
    font-size: 12px;
    width: 60%;
    line-height:22px;
  }
  @media (max-width: 450px) {
    font-size: 17px;
    width: 100%;
  }
`;

export const Start = styled(Button)`
  margin-top: 40px;
  background-color: #ff7b00;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 30px;
  font-size: 17px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  transition:1s;
  min-width: 20%;
  padding:15px;
  &:hover,
  &:active,
  &:focus {
    background-color: #fff !important;
    color:#ff7b00  !important;
  }
  @media (max-width: 1000px) {
    font-size: 9px;
  }
  @media (max-width: 650px) {
    font-size: 12px;
    width: 20%;
  }
  @media (max-width: 450px) {
    font-size: 14px;
    width: 60%;
    margin-top: 20px;
  }
`;

export const ContainBImg = styled.img`
  width: 47%;
  position: absolute;
  margin-top: -45%;
  overflow: hidden;
`;

export const ContainImg = styled.img`
  position: relative;
  z-index: 300;
  margin-right: 5%;
  margin-top: -38%;
  bottom:0%;
  @media (max-width: 450px) {
    width: 280px;
    margin-top: -60%;
  }
`;

export const BackImageCo1 = styled.img`
  width: 180px;
  position: absolute;
  bottom: -45px;
  z-index:-1;
  @media (max-width: 1000px) {
    width: 100px;
    position: relative;
    bottom: auto;
  }
  @media (max-width: 650px) {
  }
  @media (max-width: 450px) {
    width: 30px;
    position: absolute;
    bottom: 380px;
  }
`;

/*-------------------------------------container2------------------------------------------*/

export const Container2 = styled.div`
  width: 100%;
  height: 640px;
  z-index: 100;
  align-items: center;
  background-color: #d9d9ff;
  @media (max-width: 1000px) {
    width: 100%;
    height: auto;
    z-index: 100;
  }
`;

export const Details2 = styled.div`
  margin-top: 4%;
  width: 100%;
`;

export const Header2 = styled.header`
  font-size: 220%;
  font-weight: 800;
  font-family: "Montserrat", sans-serif;
  color: rgba(15, 16, 67, 1);
  text-align: center;
  @media (max-width: 1000px) {
    font-size: 30px;
  }
  @media (max-width: 650px) {
    font-size: 26px;
  }
  @media (max-width: 450px) {
    font-size: 20px;
  }
`;

export const Body2 = styled.body`
  color: rgba(109, 109, 149, 1);
  font-size: 22px;
  margin-top: 5px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  background: none;
  font-weight: 600;
  @media (max-width: 1000px) {
    font-size: 18px;
  }
  @media (max-width: 650px) {
    font-size: 16px;
  }
  @media (max-width: 450px) {
    font-size: 12px;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 4%;
  @media (max-width: 450px) {
    margin: 0px auto;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  @media (max-width: 1000px) {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
  }
`;

export const Cards = styled(Card)`
  background-color: #ffffff;
  margin: 20px;
  padding: 10px;
  box-shadow: 0 1px 10px 0 lightgray;
  border-radius: 10px;
  border: none;
  width: 23%;
  height: 355px;
  &:hover,
  &:active,
  &:focus {
    background: #3335cf 0% 0% no-repeat padding-box;
    color: white;
  }
  @media (max-width: 800px) {
    width: 100% !important;
    height: auto;
    :hover {
      background: #6c6ccf;
    }
  }
  @media (max-width: 1000px) {
    width: 45%;
    height: auto;
    :hover {
      background: #6c6ccf;
    }
  }
`;

export const CardHeader = styled(Card.Header)`
  font-size: 25px;
  color: rgba(51, 53, 207, 1);
  text-transform: capitalize;
  opacity: 1;
  display: flex;
  font-family: "Montserrat", sans-serif;
  justify-content: center;
  border: none;
  background: none;
  font-weight: bolder;
  ${Cards}:hover & {
    color: white;
  }
  @media (max-width: 1000px) {
    font-size: 20px;
  }
  @media (max-width: 650px) {
    font-size: 16px;
  }
  @media (max-width: 450px) {
    font-size: 14px;
  }
`;

export const CardBody = styled(Card.Body)`
  display: flex;
  justify-content: center;
`;

export const CardFooter = styled(Card.Footer)`
  border: none;
  display: flex;
  background: none;
  text-align: center;
  margin-bottom: 50px;
  font-size: 14px;
  font-weight: 550;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  color: #000000;
  ${Cards}:hover & {
    color: white;
  }
  @media (max-width: 1000px) {
    font-size: 12px;
    margin-bottom: 24px;
  }
  @media (max-width: 650px) {
    font-size: 10px;
    margin-bottom: 20px;
  }
  @media (max-width: 450px) {
    font-size: 12px;
    margin-bottom: 16px;
  }
`;

export const CareerKompas = styled(Figure.Image)`
  width: 30%;

  @media (max-width: 900px) {
    width: 45%;
  }
`;

export const SecondImage = styled(Figure.Image)`
  width: 30%;
  @media (max-width: 900px) {
    width: 45%;
  }
`;

export const ThirdImage = styled(Figure.Image)`
  width: 30%;
  height: "56";
  object-fit: cover;
  @media (max-width: 900px) {
    width: 45%;
    height: "56";
  }
`;

/*-------------------------------------container3------------------------------------------*/

export const Container3 = styled.div`
  width: 100%;
  height: auto;
  background: url(${Backimage1});
  background-size: cover;
  background-position: fixed;
  margin-bottom: 10px;
  @media (max-width: 450px) {
    width: 100%;
    margin: auto;
    height: auto;
  }
  @media (max-width: 1000px) {
    width: 100%;
    height: auto;
    margin: auto;
  }
`;
export const Header3 = styled.header`
  font-size: 220%;
  font-weight: 800;
  font-family: "Montserrat", sans-serif;
  color: #0f1043;
  margin-top: 50px;
  margin-bottom: -60px;
  text-align: center;
  @media (max-width: 600px) {
    margin: 10px auto;
    justify-content: center;
    text-align: center;
    // height:auto;
  }
  @media (max-width: 900px) {
    margin: 10px auto;
    justify-content: center;
    text-align: center;
    font-size: 25px;
  }
  @media (max-width: 450px) {
    margin-top: 19px;
  }
`;
export const Body3 = styled.img`
  color: #6d6d95;
  width: 100%;
  cursor:pointer;
`;

interface Props {
  hide?: boolean;
  primary?: boolean;
  secondry?: boolean;
}

export const CompassBody = styled.div`
  width: 240px;
  height: 240px;
  margin: 0 auto;
  position: relative;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 650px) {
    width: 450px;
    height: 450px;
  }
`;

const compassPoint = css`
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: 650;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  border-radius: 50%;
  position: absolute;
  z-index: 99;

  @media screen and (min-width: 650px) {
    width: 40px;
    height: 40px;
  }
`;

const compassBluePointNumber = css`
  background-color: #d1d1ff;
  border: 2px solid #6d6d95;
  color: #2e2ec0;
`;

const compassRedPointNumber = css`
  border: 2px solid #ff7b00;
  background-color: #ffe3c9;
  color: #ff7b00;
`;


const topCenter = css`
  top: 0;
  translate: -50% -80%;
  left: 50%;
`;

export const CompassFirst = styled.div<Props>`
  ${compassPoint}
  ${compassBluePointNumber}

  ${({ hide }) => hide && `
    display: none;
  `}

  ${({ primary }) => primary && `
    top: 0;
    left: 0;

    @media screen and (min-width: 650px) {
      top: 0px;
      left: 0px;
    }
  `}

  ${({ secondry }) => secondry && `
    ${topCenter}
  `}
`;

const compassCard = css`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 12px;
  background:#fff;
  color: #000124;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  box-shadow: 0px 2px 5px lightgray;
  width: 165px;
  padding: 10px 5px;

  @media screen and (min-width: 650px) {
    font-weight: bold;
    font-size: 15px;
    width: 235px;
    padding: 10px;
    min-height: 80px;
  }
`;

export const CompassFirstCard = styled.div`
  ${compassCard}
  bottom: 123px;
  right: 120px;
  
  @media screen and (min-width: 650px) {
    bottom: calc(300px - 50px);
    right: calc(400px - 54px);
    background:#fff;
  }
`;

export const CompassSecond = styled.div<Props>`
  ${compassPoint}
  ${compassRedPointNumber}

  ${({ hide }) => hide && `
    display: none;
  `}

  ${({ primary }) => primary && `
    top: 0px;
    right: 0px;

    @media screen and (min-width: 650px) {
      top: 0px;
      right: 0px;
    }
  `}

  ${({ secondry }) => secondry && `
    ${topCenter}
  `}
`;

export const CompassSecondCard = styled.div`
  ${compassCard}
    bottom: 123px;
    left: 123px;
    
    @media screen and (min-width: 650px) {
      bottom: calc(300px - 50px);
      left: calc(400px - 50px);
      background:#fff;
    }
`;

export const CompassThird = styled.div<Props>`
  ${compassPoint}
  ${compassRedPointNumber}

  ${({ hide }) => hide && `
    display: none;
  `}

  ${({ primary }) => primary && `
    bottom: 0px;
    left: 0px;

    @media screen and (min-width: 650px) {
      bottom: 0px;
      left: 0px;
    }
  `}

  ${({ secondry }) => secondry && `
    ${topCenter}
  `}
`;

export const CompassThirdCard = styled.div`
  ${compassCard}
    top: 120px;
    right: 120px;
    
    @media screen and (min-width: 650px) {
      top: calc(300px - 50px);
      right: calc(400px - 54px);
      background:#fff;
    }
`;

export const CompassFour = styled.div<Props>`
  ${compassPoint}
  ${compassBluePointNumber}

  ${({ hide }) => hide && `
    display: none;
  `}

  ${({ primary }) => primary && `
    bottom: 0px;
    right: 0px;

    @media screen and (min-width: 650px) {
      bottom: 0px;
      right: 0px;
    }
  `}

  ${({ secondry }) => secondry && `
    ${topCenter}
  `}
`;

export const CompassFourCard = styled.div`
    ${compassCard}
    top: 120px;
    left: 120px;
    
    @media screen and (min-width: 650px) {
      top: calc(300px - 50px);
      left: calc(400px - 50px);
      background:#fff;
    }
`;

export const BackImage = styled.img`
  position: absolute;
  width: 150px;
  top: 25rem;
  left: 79rem;
  z-index: 1;
`;

export const BackImageOrange = styled.img`
  position: absolute;
  width: 90px;
  height: 200px;
  left: 10px;
  @media (max-width: 700px) {
    z-index: 999;
    display: none;
  }
`;

/*-------------------------------------container4------------------------------------------*/

export const Container4 = styled.div`
  height: 600px;
  position: relative;
  background-color: #d9d9ff;
  @media (max-width: 580px) {
    width: 100%;
    height: 950px;
    //margin-bottom: 10px;
  }
  @media (max-width: 450px) {
    width: 100%;
    height: 925px;
    //margin-bottom: 10px;
  }
`;

export const Details4 = styled.div`
  margin: auto 0;
  padding-top: 25px;
`;

export const Header4 = styled.header`
  font-size: 220%;
  font-weight: 800;
  font-family: "Montserrat", sans-serif;
  color: #0f1043;
  text-align: center;
  @media (max-width: 450px) {
    margin: 0 auto;
    text-align: center;
    font-size: 2px;
  }
  @media (max-width: 1000px) {
    margin: 0 auto;
    text-align: center;
    font-size: 25px;
  }
`;

export const Body4 = styled.div`
  color: #6d6d95;
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  @media (max-width: 450px) {
    margin: 0px auto;
    text-align: center;
    font-size: 15px;
    padding: 8px;
  }
  @media (max-width: 1000px) {
    margin: 0px auto;
    text-align: center;
    font-size: 16px;
  }
`;

export const CardWrapper1 = styled.div`
  width: 100%;
  margin-left: 35px;
  @media (max-width: 900px) {
    margin: 0 auto;
    width: 100%;
  }
`;

export const CardRow = styled(Row)`
  margin: 30px 30px 30px 30px;
  padding: 1rem 10rem;
  @media (max-width: 600px) {
    padding: 10px 10px 10px 10px;
    width: 100%;
  }
  @media (max-width: 900px) {
    padding: 10px 10px 10px 10px;
    width: 100%;
  }
`;

export const LastLabels = styled.p`
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  margin-left: 14%;
  margin-top: 6%;
  color: #000;
  opacity: 1;
  width: 100%;
  color: ${({ color }) => (color ? color : null)};
`;

export const GCard = styled(Card)`
  background-color: #ffffff;
  width: 95px;
  border-radius: 5px;
  box-shadow: 0 1px 10px 0 lightgray;
  border: none;
  margin: 5px;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  height: 95px;
  &:hover,
  &:active,
  &:focus {
    background: #3335cf 0% 0% no-repeat;
  }
  @media (max-width: 600px) {
    padding-top: 10px;
  }
`;

export const Image = styled.img`
  width: 50px;
  height: 50px;
  @media (max-width: 600px) {
    width: 50%;
    height: 50px;
  }
`;

export const Labels = styled.p`
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  margin-top: 6%;
  color: #000;
  opacity: 1;
  width: 100%;
  color: ${({ color }) => (color ? color : null)};
`;

export const FirstLabels = styled.p`
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  margin-left: 3.5%;
  margin-top: 6%;
  color: #000;
  opacity: 1;
  width: 100%;
  color: ${({ color }) => (color ? color : null)};
`;

export const BackImageC4 = styled.img`
  position: absolute;
  width: 90px;
  height: 175px;
  top: 25rem;
  left: 1rem;
  z-index: 1;
  @media (max-width: 1000px) {
    z-index: 1;
    display: none;
  }
`;

/*-------------------------------------Footer------------------------------------------*/

export const PrivacyContainer = styled.div`
  background-color: #f8f8fe;
  width: 100%;
  padding:30px;
`;

export const WrapperC4 = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 450px) {
    display: block;
    margin:auto;
  } 
`;

export const Logo1 = styled.img`
  height: 100%;
  width: 145px;
  @media (max-width: 450px) {
    display: block;
    margin:auto;
  } 
`;

export const LogoWrap = styled.div`
  display: flex;
  @media (max-width: 450px) {
    display: block;
  } 
`;

export const BrandIcon = styled.div`
  display: flex;
  justify-content: center;
  background: none;
  font-size: 15px;
  border: none;
  color: #000;
  gap: 8px;
  .link {
    width: 32px;
  }
  @media (max-width: 750px) {
    margin: 0;
  }
  @media (max-width: 450px) {
    margin: 0;
  }
`;

export const CopyRight = styled.div`
  display: flex;
  justify-content: center;
  background: none;
  font-size: 15px;
  border: none;
  color: #000;
  gap: 8px;
  .link {
    width: 32px;
  }
  @media (max-width: 750px) {
    margin: 0;
  }
  @media (max-width: 450px) {
    margin: 10px auto;
  }
`;


export const FooterIcon = styled.img`
  width: 100%;
`;

// export const FinalFooter = styled.div`
//   font-size: 11px;
//   padding: 6px 60px;
//   color: rgba(103, 114, 148, 1);
//   text-transform: capitalize;
//   margin-top: 50px;
//   margin-bottom: 20px;
//   justify-content: space-between;
//   display: flex;
//   font-family: "Montserrat", sans-serif;
//   flex-wrap: wrap;
//   cursor: pointer;
//   background: #ebebf8;
//   #footer-text {
//     color: red;
//   }
//   @media (max-width: 600px) {
//     padding: 6px 15px;
//     margin-top: -0px;
//     display: flex;
//     justify-content: center;
//     margin: 1rem;
//   }
// `;

export const FinalWrapper = styled.div`
  min-height: 900px;
`;

export const Errorwrapper = styled.div` 
font-size: 11px;
position: relative;
color: red;
top: -16px;

`

/*---------------------------------------------END----------------------------------------------------*/
