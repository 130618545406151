import {
  ChangeEvent,
  ReactElement,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import {
  Container1,
  Container2,
  PageWrapper,
  Header,
  Body,
  Footer,
  Details,
  Start,
  Details2,
  Header2,
  Body2,
  Cards,
  CardWrapper,
  CardHeader,
  CardBody,
  CardFooter,
  Container3,
  Header3,
  Body3,
  ContainImg,
  ContainBImg,
  CareerKompas,
  ThirdImage,
  SecondImage,
  CompassFirst,
  CompassSecond,
  CompassThird,
  CompassFour,
  CompassFirstCard,
  CompassSecondCard,
  CompassThirdCard,
  CompassFourCard,
  CompassWrapper,
  BackImage,
  BackImageCo1,
  BackImageOrange,
  Errorwrapper,
  CompassBody,
} from "./subcomponents";
import logo from "../../assests/logo.png";

import compassBeforeClick from "../../assests/compassBefore.png";
import compassAfterClick from "../../assests/compassAfter.png";
import Footers from "../LandingPage/footer/index";
import Backimage11 from "../../assests/contain5.png";
import Group from "../../assests/Group 2112.svg";
import identify from "../../assests/identify.svg";
import advance from "../../assests/advance.svg";
import Backimage12 from "../../assests/contain2.png";
import lock from "../../assests/login.png";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ROUTES from "../../const/routes";
import { useHistory } from "react-router-dom";
import { RootState } from "../../redux/store";
import {
  handleAuthenticate,
  updateHasError,
  updateShowLogin,
} from "../../redux/login/action";
import { RegisterModalDialog } from "./RegisterStyle/subcomponents";
import { FlexWrapper, Loader, ToastMessage } from "../../components";
import { AddUserApi, getCountries } from "../../redux/Register/action";
import { getStates } from "../../redux/Register/api";
import Register from "./RegisterStyle";
import { getOTP, validateOTP } from "../../redux/streamMaster/api";
import MainHeader from "../../components/Header";
import { Otpreset } from "./const";
import containerimg from "../../assests/container3backImg.svg";
import Co1Back from "../../assests/co1Back.svg";
import BackImageC04 from "../../assests/about.png";

const User = (): ReactElement => {
  const {
    isLoading,
    isLoggedIn,
    registerDetails,
    message,
    hasOtpError = false,
    hasError = false,
    newUser,
    hasGetOtp,
    showLogin,
  } = useSelector(
    (state: RootState) => ({
      isLoading: state.login.isLoading,
      isLoggedIn: state.login.isLoggedIn,
      hasError: state.login.hasError,
      registerDetails: state.register.registerDetails,
      message: state.stream.otp?.message,
      hasOtpError: state.stream.hasOtpError,
      hasGetOtp: state.stream.hasGetOtp,
      newUser: state.login.userInfo?.newUser,
      showLogin: state.login.showLogin,
    }),
    shallowEqual
  );
  const [showRegister, setShowRegister] = useState(false);
  const [showOtp, setShowOtp] = useState<any>("");
  const [values, setValues] = useState(registerDetails);

  const [otpvalues, setOTPValues] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [clearvaluesOTP, setClearValuesOTP] = useState(Otpreset);
  const [errorss, setErrors] = useState("");
  const [errorsspass, setErrorspass] = useState("");
  const [time, setTime] = useState(59);
  const [loginValues, setloginValues] = useState({
    email: '',
    pass: '',
  });
  const dispatch = useDispatch();
  const history = useHistory();

  const Otpclear = () => {
    setClearValuesOTP({
      otp: true,
    });
    setOTPValues("");
  };

  const handleSubmitOTP = () => {
    dispatch(
      validateOTP({
        id: `${message}`,
        otpcode: otpvalues,
      })
    );
    Otpclear();
  };

  useEffect(() => {
    if (message === "Verification Successful") {
      dispatch(updateShowLogin(true));
      setShowOtp(false);
      dispatch(AddUserApi(values));
      alert("Successful");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  //getState
  useEffect(() => {
    dispatch(getCountries());
    dispatch(getStates({ id: "101" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setOtpTime = (time: number) => {
    if (time === 0) {
      return;
    } else {
      const timeout = setTimeout(() => {
        setTime(time - 1);
      }, 1000);
      return () => clearInterval(timeout);
    }
  };

  // useEffect(() => {
  //   if (hasOtpError) {
  //     setshowTost(true)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [message]);

  //compass
  const [map, showMap] = useState(false);
  const [compass, setcompass] = useState({
    first: false,
    second: false,
    third: false,
    four: false,
  });

  const Img = map ? compassAfterClick : compassBeforeClick;

  const canSubmit = !!loginValues.email && !!loginValues.pass;

  return (
    <>
    <PageWrapper>
      <MainHeader />
      <Container1>
        <Details>
          <Footer>
            Welcome to Careerkompass
          </Footer>
          <Header>The Path to<br/>Professional Potential!</Header>
          <Body>Let's embark on a journey of discovery.</Body>
          <Start
            onClick={() => {
              if (isLoggedIn) {
                if (newUser === false) {
                  history.push(ROUTES.EXISTING_USER);
                } else if (newUser === true) {
                  history.push(ROUTES.STREAMSELECTION)
                }
              } else {
                dispatch(updateShowLogin(true));
              }
            }}
          >
            FIND YOURSELF!
          </Start>
        </Details>
        {/* <BackImageCo1 src={Co1Back} /> */}
      </Container1>

      <FlexWrapper justifyContent="right" noPadding>
        <ContainImg src={Backimage11} />
        <ContainBImg src={Backimage12} />
      </FlexWrapper>

      <Container2>
        <Details2>
          <Header2>Discover your best Career path</Header2>
          <Body2>with Careerkompass's top-notch professional guidance</Body2>
        </Details2>
        <CardWrapper>
          <Cards>
            <CardHeader>Explore</CardHeader>
            <CardBody>
              <CareerKompas src={Group} />
            </CardBody>
            <CardFooter>
              Careerkompass computes your personality traits, strong interests
              and aligns them with work skills to determine the best career
              options in varied work environments
            </CardFooter>
          </Cards>
          <Cards>
            <CardHeader>Identify</CardHeader>
            <CardBody>
              <SecondImage src={identify} />
            </CardBody>
            <CardFooter>
              The testing elements capture your approach to various scenarios,
              identifying your strengths, attitude, aptitude, and more. Also,
              established on the lines of real jobs and industries for career
              satisfaction
            </CardFooter>
          </Cards>
          <Cards>
            <CardHeader>Advance</CardHeader>
            <CardBody>
              <ThirdImage src={advance} />
            </CardBody>
            <CardFooter style={{ marginBottom: "65px" }}>
              Receive an inventory of career interests and a personalized guide
              to steer your career forward on a new journey!
            </CardFooter>
          </Cards>
        </CardWrapper>
      </Container2>

      <Container3>
        <BackImageOrange src={BackImageC04} />
        <Header3>How it Works? </Header3>
        <CompassWrapper>
          <CompassBody>
            {map && (
              <>
                <CompassFirst
                  hide={!!compass.first}
                  primary={true}
                  onClick={() =>
                    setcompass({
                      first: !compass.first,
                      second: false,
                      third: false,
                      four: false,
                    })
                  }
                >
                  1
                </CompassFirst>
                {compass.first && (
                  <>
                    <CompassFirstCard>
                      <CompassFirst
                        secondry={true}
                        onClick={() =>
                          setcompass({
                            first: !compass.first,
                            second: false,
                            third: false,
                            four: false,
                          })
                        }
                      >
                        1
                      </CompassFirst>
                      Personal/Professional Details entry Desk
                    </CompassFirstCard>
                  </>
                )}
                <CompassSecond
                  hide={!!compass.second}
                  primary={true}
                  onClick={() =>
                    setcompass({
                      first: false,
                      second: !compass.second,
                      third: false,
                      four: false,
                    })
                  }
                >
                  2
                </CompassSecond>
                {compass.second && (
                  <CompassSecondCard>
                    <CompassSecond
                      secondry={true}
                      onClick={() =>
                        setcompass({
                          first: false,
                          second: !compass.second,
                          third: false,
                          four: false,
                        })
                      }
                    >
                      2
                    </CompassSecond>
                    Aspiration finder/Aspiration finder-scope:
                    <div style={{ textAlign: "left" }}>
                      <div style={{ color: "tomato" }}> Level 1: Expert </div>
                      <p className="c2-expert" style={{ marginBottom: "14px" }}>
                        Unique assessment centre for expert
                      </p>
                      <div style={{ color: "tomato" }}>
                        {" "}
                        Level 2: Product head{" "}
                      </div>
                      <p className="c2-expert" style={{ marginBottom: "0px" }}>
                        Unique assessment centre for Product head
                      </p>
                    </div>
                  </CompassSecondCard>
                )}
                <CompassThird
                  hide={!!compass.third}
                  primary={true}
                  onClick={() =>
                    setcompass({
                      first: false,
                      second: false,
                      third: !compass.third,
                      four: false,
                    })
                  }
                >
                  3
                </CompassThird>
                {compass.third && (
                  <CompassThirdCard>
                    <CompassThird
                      secondry={true}
                      onClick={() =>
                        setcompass({
                          first: false,
                          second: false,
                          third: !compass.third,
                          four: false,
                        })
                      }
                    >
                      3
                    </CompassThird>
                    Result Chamber/Evaluation Arena
                    <div className="c3-container">Discover Your badge</div>
                  </CompassThirdCard>
                )}
                <CompassFour
                  hide={!!compass.four}
                  primary={true}
                  onClick={() =>
                    setcompass({
                      first: false,
                      second: false,
                      third: false,
                      four: !compass.four,
                    })
                  }
                >
                  4
                </CompassFour>
                {compass.four && (
                  <CompassFourCard>
                    <CompassFour
                      secondry={true}
                      onClick={() =>
                        setcompass({
                          first: false,
                          second: false,
                          third: false,
                          four: !compass.four,
                        })
                      }
                    >
                      4
                    </CompassFour>
                    Personalized career reports vaults
                  </CompassFourCard>
                )}
              </>
            )}
            <Body3
              onClick={() => {
                showMap(!map);
                setcompass({
                  first: false,
                  second: false,
                  third: false,
                  four: false,
                });
              }}
              src={Img}
            />
            <BackImage src={containerimg} />
          </CompassBody>
        </CompassWrapper>
      </Container3>
      <Footers />
    </PageWrapper>
    </>
  );
};

export default User;
