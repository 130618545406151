import React from 'react';
import { Button } from "react-bootstrap";
import styled, { css } from "styled-components";
import Back from "../../assests/streamBackGrounf.png";
import BodyBack from "../../assests/selectStreamBack.svg";

export const ContainerWrapper = styled.div`
  background: url(${Back});
  background-position: center;
  background-size: cover;
  width: 100%;
  overflow: hidden;
  @media (max-width: 700px) {
    width: 100%;
    height: auto;
  }
`;

export const SelectLevelWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0 25px 0;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-left: 50px;
  width: 90%;
  @media (max-width: 600px) {
    margin: 10px 0px 10px -10px;
    width: 100%;
  }
`;

export const Header1 = styled.div`
  letter-spacing: 0px;
  color: #0f1043;
  opacity: 1;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  font-size: 38px;
  font-weight: 900;
  position: relative;
  @media (max-width: 650px) {
    width: 100%;
    font-size: 160%;
  }
  @media (max-width: 450px) {
    z-index: 0;
  }
`;

interface Header2props {
  fontSize?: string;
}
export const Header2 = styled.div<Header2props>`
  letter-spacing: 0px;
  color: #6d6d95;
  opacity: 1;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "28px")};
  font-weight: 600;
`;

export const Body = styled.div`
  margin-top: 86px;
`;
export const Para = styled.p`
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  width: 60%;
  @media (max-width: 650px) {
    width: 100%;
    font-size: 70%;
  }
`;
export const StreamWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 13px;
  @media (min-width: 600px) {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  @media (min-width: 900px) {
    margin: 0 auto;
    margin-top: 25px;
    width: 100%;
  }
`;

export const StreamSelectionWrapper = styled.div`
  margin: 0 auto;
  width: 350px;
  @media (min-width: 600px) {
    width: 386px;
    
  }
`;

export const DropdownWrapper = styled.div`
  width: 100%;
  box-shadow: 0px 3px 6px #0000000F;
  #editable-dropdown {
    height: 100%;
  }
`;

export const LevelCard = styled(Button)`
  background: lightgray;
  height: 56px;
  ${props => props.responsive && css`
    height: auto;`
  }
  margin: 4px;
  margin-top: -8px;
  font-family: "Montserrat", sans-serif;
  border-radius: 5px;
  border: none;
  position: relative;
  font-weight: 500; 
  font-size: 15px;
  color: black;  
  &:hover {
    background-color: #ff7b00;
    color: white;
  }
  &:active,
  &:focus{
    background-color: #F47F34;
  }
  span {
    font-family: "Montserrat", sans-serif;
    font-size: 12px; 
  }
`;

export const TestButton = styled(Button)`
  position: absolute;
  height: 35px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  background-color: #1E1E39;
  color: #ffffff;
  opacity: 1;
  border: none;
`;

export const CircleImagec = styled.img`
  position: relative;
  width: 150px;
  top: 15px;
  right: 39rem;
`;

export const Gearup = styled.div`
  font-size: 13px !important;
  text-align: center;
  padding-bottom: 40px;
  margin-top: -25px;
}  
`;

export const BodyBackImage = styled.div`
  background: url(${BodyBack});
  background-size: cover;
  width: 95%;
  height: 190px;
  position: absolute;
  overflow: hidden;
  right: 30px;
  z-index: 0;
`;
